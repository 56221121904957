import {elementWithinClass, loadImage} from "../utils";
import {TweenLite} from "gsap/TweenLite";

var activeLightboxGroup = 0;
var lightBoxes;

var isTouch = false;

import overlay from "./overlay";
import lockView from "./lockView";

addEventListener('touchstart', touchCheck);

function touchCheck(e) {
    isTouch = true;
    removeEventListener('touchstart', touchCheck);
}

var Lightbox = function (lightBoxGroup) {

    var t = this;

    this.lightBoxGroup = lightBoxGroup;
    this.lightBoxItems = [].slice.call(t.lightBoxGroup.getElementsByClassName('lightBoxItem'));
    this.itemLength = t.lightBoxItems.length;
    this.activeIndex = 0;

    this.clickHandler = this.clickHandler.bind(this);
    this.keypressHandler = this.keypressHandler.bind(this);

    lightBoxGroup.addEventListener('click', function (event) {

        event.preventDefault();

        var clickedItem = elementWithinClass(event.target, 'lightBoxItem');

        if (clickedItem) {
            t.activeIndex = t.lightBoxItems.indexOf(clickedItem);
            t.vars.loadUrl = clickedItem.href;
            t.openLightbox('click');
        }
    });
};

Lightbox.prototype.clickHandler = function (event) {

    if (event.target === Lightbox.prototype.vars.nextBtn) {
        this.changeImage(true, 0);
    } else if (event.target === Lightbox.prototype.vars.prevBtn) {
        this.changeImage(false, 0);
    } else if (event.target === Lightbox.prototype.vars.lightBoxActive || event.target === Lightbox.prototype.vars.closeBtn) {
        this.closeLightbox();
    } else if (event.target === Lightbox.prototype.vars.zoomBtn) {
        this.zoom();
    } else if (!this.zoomActive && event.target === Lightbox.prototype.vars.activeLightboxImage) {
        !isTouch && this.zoom();
    }
};

Lightbox.prototype.keypressHandler = function (event) {

    event.keyCode === 27 || event.keyCode === 40 ? this.closeLightbox() : false; //escape
    event.keyCode === 39 || event.keyCode === 32 ? this.changeImage() : false; //escape
    event.keyCode === 37 ? this.changeImage(true) : false; //escape
    event.preventDefault();
};

Lightbox.prototype.vars = {
    lightBoxTemplate: '<div class="lightBox" id="lightBox">' +
        '<header class="lightBoxHeader" id="lightBoxHeader">' +
        '<a class="icon-close" id="lightBoxCloseBtn"></a>' +
        '</header>' +
        '<div class="lightBoxContent" id="lightBoxContent">' +
        '<div class="lightBoxImageWrapper" id="lightBoxActive"></div>' +
        '</div>' +
        '<footer class="lightBoxFooter" id="lightBoxFooter">' +
        '<nav id="lightBoxControls" class="lightBoxControls">' +
        '<a class="icon-chevron-thin-left" id="lightBoxPrevBtn"></a>' +
        // '<a class="icon-search" id="lightBoxZoomBtn"></a>' +
        '<a class="icon-chevron-thin-right" id="lightBoxNextBtn"></a>' +
        '</nav>' +
        '<div id="lightBoxCaption"></div>' +
        '</footer>' +
        '' +
        '</div>'
};

// Lightbox.prototype.zoom = function() {
//     var t = this;
//     var lightBoxActive = t.vars.lightBoxActive;
//
//     function closePanZoom() {
//         $(lightBoxActive).panzoom('reset', {
//             animate: true,
//             duration: 10
//         });
//         TweenLite.to(lightBoxActive, 0.2, {
//             scale: 1,
//             ease: Power2.easeOut,
//             onComplete: function() {
//                 TweenLite.to(t.vars.lightBoxFooter,.2, { yPercent: 0, padding:'' });
//                 TweenLite.set(t.vars.lightBoxCaption, { height: 'auto'});
//                 TweenLite.from(t.vars.lightBoxCaption,.2, { height: 0});
//                 setTimeout(function() {
//                     $(lightBoxActive).panzoom('destroy');
//                 },100);
//                 t.zoomActive = false;
//             }
//         });
//         TweenLite.to(t.vars.zoomBtn,.15, {scale: 1});
//         setTimeout(function() {
//             TweenLite.to(t.vars.nextBtn,.3, { x:0, opacity: 1});
//             TweenLite.to(t.vars.prevBtn,.3, { x:0, opacity: 1});
//         },200);
//         t.dragItem && t.dragItem.enable();
//     }
//
//     t.zoomActive = !t.zoomActive;
//
//     if(t.zoomActive) {
//         TweenLite.to(t.vars.lightBoxFooter,.2, { yPercent: 100, padding:0});
//         TweenLite.to(t.vars.lightBoxCaption,.2, { height: 0 });
//
//         TweenLite.to(lightBoxActive, 0.2, {scale: 1.2});
//
//
//         (function() {
//             t.vars.zoomBtn.RemoveClass('icon-zoomin');
//             t.vars.zoomBtn.AddClass('icon-zoomout');
//             var $panzoom = $(lightBoxActive).panzoom({
//                 duration: 120,
//                 animate: true
//             });
//
//             function wheelHandler(event) {
//                 event.preventDefault();
//                 var delta = event.deltaY * -1;
//                 var zoomOut = delta ? delta < 0 : delta > 0;
//                 $panzoom.panzoom('zoom', zoomOut, {
//                     increment: 0.1,
//                     minScale: 1,
//                     contain: true,
//                     easing: "ease-out",
//                     focal: event
//                 });
//                 setTimeout(function() {
//
//                     if(t.zoomActive && getScale(lightBoxActive) <= 1.0) {
//                         lightBoxActive.removeEventListener('wheel', wheelHandler);
//                         closePanZoom();
//                     }
//                 }, 10);
//             }
//
//             lightBoxActive.addEventListener('wheel', wheelHandler);
//
//             $panzoom.on('panzoomend', function(e, $panzoom, matrix, changed) {
//                 if(changed && t.zoomActive) {
//                     if($panzoom.getMatrix().slice()[0] <= 1.0) {
//                         lightBoxActive.removeEventListener('wheel', wheelHandler);
//                         closePanZoom();
//                     }
//                 }else {
//                     lightBoxActive.removeEventListener('wheel', wheelHandler);
//                     closePanZoom();
//                 }
//             });
//         })();
//
//         t.dragItem && t.dragItem.disable();
//         setTimeout(function() {
//             TweenLite.to(t.vars.zoomBtn,.15, { scale: 1.2});
//             TweenLite.to(t.vars.nextBtn,.3, { x:-80, opacity: 0});
//             TweenLite.to(t.vars.prevBtn,.3, { x:80, opacity: 0});
//         },200);
//     }else {
//         closePanZoom();
//     }
// };

Lightbox.prototype.addShell = function (callback) {

    document.body.insertAdjacentHTML('beforeend', this.vars.lightBoxTemplate);

    this.vars.lightBoxShell = document.getElementById('lightBox');
    this.vars.lightBoxHeader = document.getElementById('lightBoxHeader');
    this.vars.lightBoxContent = document.getElementById('lightBoxContent');
    this.vars.lightBoxActive = document.getElementById('lightBoxActive');
    this.vars.lightBoxFooter = document.getElementById('lightBoxFooter');
    this.vars.lightBoxCaption = document.getElementById('lightBoxCaption');
    this.vars.lightBoxControls = document.getElementById('lightBoxControls');
    // this.vars.zoomBtn = document.getElementById('lightBoxZoomBtn');
    this.vars.closeBtn = document.getElementById('lightBoxCloseBtn');
    this.vars.prevBtn = document.getElementById('lightBoxNextBtn');
    this.vars.nextBtn = document.getElementById('lightBoxPrevBtn');

    TweenLite.set(this.vars.lightBoxContent, {perspective: 600});
    TweenLite.from(this.vars.lightBoxFooter, .2, {yPercent: 100, delay: .3});
    TweenLite.from(this.vars.lightBoxHeader, .2, {yPercent: -100, delay: .3});
    TweenLite.from(this.vars.lightBoxControls, .2, {yPercent: 40, opacity: 0, delay: .38, clearProps: 'transform'});

    callback ? callback() : false;
};

Lightbox.prototype.setDraggable = function (item) {

    var t = this,
        startX,
        dragX,
        direction;

    t.dragInstance && t.dragInstance.kill();

    var draggable = Draggable.create(item, {
        type: "x",
        minimumMovement: 26,
        zIndexBoost: false,
        trigger: '#lightBoxActive',
        onDragStart: function () {
            startX = t.dragItem.x;
        },
        onDrag: function () {
            dragX = t.dragItem.x;
            if (dragX < startX) {
                direction = 'forward';
            } else {
                direction = 'backwards';
            }
        },

        onDragEnd: function () {

            var dur = .2,
                xMovement = t.dragItem.endX,
                threshold = 80;

            //animate on release beyond threshold
            if (xMovement < (startX - threshold)) {         // forward
                t.changeImage();
            } else if (xMovement > (startX + threshold)) {   // backwards
                t.changeImage(true);
            } else {                                         // threshold not passed, animate back into place
                dur = .12;
                TweenLite.to(item, dur, {x: 0, xPercent: -50, ease: Linear.easeNone});
            }
        }
    });

    t.dragInstance = draggable[0];
    t.dragItem = Draggable.get(item);
};

// Adds or Replaces a new image in lightBoxActive and make the image draggable

Lightbox.prototype.addImage = function (reverse, dur) {

    if (typeof dur !== 'number') {
        dur = .3;
    }

    var t = this;
    var fromProps = reverse ? {
        opacity: 0,
        xPercent: 0,
        z: -100
    } : {
        xPercent: 0,
        opacity: 0,
        z: -100
    };

    t.vars.loadUrl = t.vars.loadItem.href;
    t.vars.loadCaption = t.vars.loadItem.dataset.caption ? '- ' + t.vars.loadItem.dataset.caption : '';

    loadImage(t.vars.loadUrl, function () {

        t.vars.lightBoxCaption.innerHTML = (t.activeIndex + 1) + '/' + t.itemLength + ' ' + t.vars.loadCaption;
        t.vars.lightBoxActive.innerHTML = '<img class="activeLightboxImage lightBoxImage" src="' + t.vars.loadUrl + '"/>';
        t.vars.activeLightboxImage = document.getElementsByClassName('activeLightboxImage')[0];

        t.itemLength > 1 && t.setDraggable(t.vars.activeLightboxImage);

        TweenLite.set(t.vars.activeLightboxImage, {
            xPercent: -50,
            yPercent: -50,
        });

        TweenLite.fromTo(t.vars.lightBoxActive, dur, fromProps, {
            // onStart: function() {
            //     box.setDraggable(box.vars.activeLightboxImage);
            // },
            scale: 1,
            opacity: 1,
            z: 0,
            force3D: true
        });

        // preload
        loadImage(t.vars.nextItem.href, function () {
            t.nextIndex + 1 < t.itemLength ? loadImage(t.lightBoxItems[t.nextIndex + 1].href) : false;
        });
    });

};

// Opens a lightBox and adds event listeners

Lightbox.prototype.openLightbox = function (eventType) {

    this.constructor.activeLightbox = this;
    this.constructor.lastActive = this;

    var t = this;
    t.vars.loadItem = t.lightBoxItems[t.activeIndex -= 1];

    if (!history.state.lightBox) {
        history.pushState(Object.assign(history.state, {lightBox: true}), 'bildevisning');
    }

    lockView.lockView();
    this.overlay = overlay.add({
        opacity: .8,
        onComplete: function () {
            t.addShell(function () {

                // isTouch && (t.vars.zoomBtn.style.display = 'none');

                TweenLite.set([t.vars.nextBtn, t.vars.prevBtn], {
                    display: t.itemLength === 1 && 'none' || ''
                });

                t.changeImage();

                // t.vars.lightBoxActive.addEventListener('wheel', function(event) {
                //     if(!t.zoomActive && event.deltaY * -1 > 0) {
                //         t.zoom();
                //     }
                // });

            });
        }
    });

    window.addEventListener('click', t.clickHandler);
    window.addEventListener('keyup', t.keypressHandler);
};

// Animates out lightBoxActive and sets a new activeIndex for addImage() to handle when animation is done

Lightbox.prototype.changeImage = function (reverse, dur) {

    if (typeof dur !== 'number') {
        dur = .15
    }

    var t = this;
    reverse = reverse ? reverse : false;

    if (!reverse) {
        //if active item is not last item, set next +1, if not go to 0
        this.activeIndex != this.itemLength - 1 ? this.activeIndex++ : this.activeIndex = 0;
    } else {
        //if active item is first item, set next to last, if not set next -1 -1
        this.activeIndex === 0 ? this.activeIndex = this.itemLength - 1 : this.activeIndex--;
    }

    this.nextIndex = this.activeIndex != this.itemLength - 1 ? this.activeIndex + 1 : 0;

    this.vars.loadItem = this.lightBoxItems[this.activeIndex];
    this.vars.nextItem = this.lightBoxItems[this.nextIndex];

    var toProps = {
        opacity: 0,
        onComplete: function () {
            t.addImage(reverse, dur);
        }
    };

    toProps.xPercent = reverse ? 20 : -20;

    TweenLite.to(t.vars.lightBoxActive, dur, toProps);

};

// Closes the lightBox and removes markup and eventListeners

Lightbox.prototype.closeLightbox = function () {

    // this.zoomActive = false;
    this.constructor.active = null;
    this.dragInstance && this.dragInstance.kill();

    if (history.state.lightBox) {

        history.state.lightBox ? history.back() : false;
    } else {

        window.removeEventListener('keyup', this.keypressHandler);
        window.removeEventListener('click', this.clickHandler);
        this.overlay.remove();
        lockView.unlockView();
        document.body.removeChild(this.vars.lightBoxShell);
    }
};

//-- Init lightBoxes -------------------------------//
//
// window.addEventListener('load', function() {
//     lightBoxes = [];
//     for (var i = 0; i < document.getElementsByClassName('lightBoxGroup').length; i++) {
//         var group = document.getElementsByClassName('lightBoxGroup')[i];
//         lightBoxes[i] = new Lightbox(group);
//     }
// });
//

window.addEventListener('popstate', function () {

    if (history.state.lightBox) {

        // console.log('1 state lightbox', history.state);
        Lightbox.activeLightbox.openLightbox('pop');

    } else {

        // console.log('2 ! state lightbox', history.state);
        Lightbox.activeLightbox && Lightbox.activeLightbox.closeLightbox();
    }

});

export default Lightbox;
