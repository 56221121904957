import {C, Q} from '../J';
import RevealingGallery from "../components/revealingGallery";
import Lightbox from "../components/lightbox";

function createGallery(element, vars) {

    var rg = new RevealingGallery(element, vars);

    if (vars.captions) {

        var caption = C('div', {"class": "gallery-caption"});
        element.parentNode.insertBefore(caption, element.nextSibling);
        rg.onChange = function (o) {
            setCaption();
        };

        setCaption();
    }

    if (rg.rootElement.classList.contains('rg-invalid')) {
        return false;
    }

    var firstImg = rg.items[0];

    var prevBtn = C('button', {"class": "gallery-controls-btn btn-prev", title: "Forrige"}),
        nextBtn = C('button', {"class": "gallery-controls-btn btn-next", title: "Neste"});

    prevBtn.innerHTML = ('<i class="icon-chevron-thin-left"></i>');
    nextBtn.innerHTML = ('<i class="icon-chevron-thin-right"></i>');

    element.appendChild(prevBtn);
    element.appendChild(nextBtn);

    prevBtn.onclick = rg.prev.bind(rg, true);
    nextBtn.onclick = rg.next.bind(rg, true);


    function setCaption() {

        var desc = rg.active.dataset.desc || '';
        var credits = rg.active.dataset.credits || '';

        caption.innerHTML = /*(rg.activeIndex + 1) + '/' + (rg.items.length) +*/
            '<span class="description">' + desc + '</span>';

        if (credits) {
            caption.innerHTML += '<span class="description">Foto: ' + credits + '</span>';
        }
    }

    //load first image, then goto/reposition
    if (firstImg.src) {
        var toLoad = document.createElement('img');
        toLoad.onload = function () {
            rg.goTo(0);
        };
        toLoad.src = firstImg.src;
    }

    return rg;
}

var revolverGallery = function (token) {

    //todo: clean up

    var root = token.element;
    var lb = token.vars.lightbox && new Lightbox(root);
    var gallery = createGallery(root, token.vars);

    token.destroy = function () {
        gallery.destroy();
        lb && lb.destroy();
    };

    return token
};

export default revolverGallery;
module.exports = revolverGallery;
