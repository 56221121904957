// import

import {importScript} from "../../utils";
import {C} from '../../J';

if (typeof globalContext == 'undefined') {
    window.globalContext = {
        map: {
            key: 'AIzaSyBdUAcnQWuMIB8hT8w39S14LcIU7wdslq0'
        }
    };
}

var config = {
    key: globalContext.map.key,
    geocodingApi: 'https://maps.googleapis.com/maps/api/geocode/json?',
    defaultStyles: [
        {
            "featureType": "poi",
            "stylers": [{
                "visibility": "off"
            }]
        }
    ]
};

if (!config.key) {
    console.log('OBS: globalContext.map.key is undefined. Fallback to staging key');
    config.key = 'AIzaSyBdUAcnQWuMIB8hT8w39S14LcIU7wdslq0';
}

///////////// methods

function createMap(el, vars) {

    vars = vars || {};
    var map = new google.maps.Map(el, {
        mapTypeId: 'hybrid',
        zoom: vars.zoom || globalContext.map.zoom,
        center: vars.center || globalContext.map.pos,
        gestureHandling: vars.gestureHandling || 'cooperative'
    });

    map.setOptions({
        styles: config.defaultStyles
    });

    return map;
}

function fitToMarkers(map, markers) {

    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < markers.length; i++) {
        bounds.extend(markers[i].getPosition());
    }

    map.fitBounds(bounds);
}


function createMarker(o) {

    return new google.maps.Marker(o);
}


function destinationLinkFromItem(item) {

    var destinationBase = 'https://maps.google.com/maps/dir/?api=1&destination=';
    return item.pos
        && destinationBase + [item.pos.lat, item.pos.lng].join(',')
        || null;
}

function listenOnce() {
    google.maps.event.addListenerOnce.apply(false, arguments);
}

var renderInfoWindow = {

    article: function (item) {

        var destinationLink = destinationLinkFromItem(item),
            links = {};

        return C('article', {class: 'iw-item'}).Append(
            //main content
            C('a', {class: "iw-item-main", href: item.link})
                .Append(
                    C('div', {class: "iw-item-main-img"})
                        .Append(
                            C('img', {src: item.img, alt: 'Objektbilde'})
                        ),
                    C('div', {class: "iw-item-main-content"})
                        .Html(item.body)
                        .Prepend(
                            C('h1', {class: 'iw-item-main-h'}).Html(item.title)
                        )
                ),

            //footer content
            C('div', {class: "iw-item-footer"})
                .Append(
                    destinationLink && C('a', {
                        href: destinationLink,
                        "class": 'directions-link',
                        target: '_blank'
                    }).Text('Veibeskrivelse')
                )
        )
    },

    place: function (place) {

        var t = this;

        return C('div', {class: 'iw-item-view'}).Append(
            C('div', {class: 'iw-scroll iw'}).Append(
                C('article', {class: 'iw-place'}).Append(
                    C('h1').Html(place.title),
                    C('div', {class: 'iw-place-items'}).Append(
                        function () {
                            var items = place.items, len = place.items.length, i, ret = [];
                            for (i = 0; i < len; i++) {
                                ret.push(t.article(items[i]))
                            }
                            return ret;
                        }
                    )
                )
            )
        );
    },

    item: function (item) {

        var t = this;

        return C('div', {class: 'iw-item-view'}).Append(
            C('div', {class: 'iw-scroll iw'}).Append(
                t.article(item)
            )
        );
    },
};


///////////// methods

var gLoaded = false;
var snazzyLoaded = false;

export default async function () {

    (typeof google === 'undefined' || !google.maps) && !gLoaded && (gLoaded = true) &&
    await importScript('https://maps.googleapis.com/maps/api/js?key=' + config.key);

    typeof SnazzyInfoWindow === 'undefined' && !snazzyLoaded && (snazzyLoaded = true) &&
    await importScript(globalContext.map.snazzyPath);

    return {
        google: google,
        SnazzyInfoWindow: SnazzyInfoWindow,
        createMap,
        fitToMarkers,
        createMarker,
        listenOnce,
        renderInfoWindow
    }
};
