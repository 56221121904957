/**
 * Created by havard on 16.01.2018.
 */

import JModal from "../components/JModal";
import lockView from "../components/lockView";

const m = new JModal;

const modal = {

    dom: m.dom,

    state: {
        status: 'idle'
    },

    setContent: function () {

        m.setContent.apply(m, arguments);
        return this;
    },

    setClass: function () {

        m.setClass.apply(m, arguments);
        return this;
    },

    open: function (vars) {

        vars = vars || {};
        var t = this;

        var conf = {

            addClass: 'modal',

            beforeOpen: function () {
                lockView.lockView();
                vars.beforeOpen && vars.beforeOpen.call(m)
            },

            afterOpen: function () {
                t.state.status = 'open';
                vars.afterOpen && vars.afterOpen.call(m)
            },

            beforeClose: function () {
                vars.beforeClose && vars.beforeClose.call(m)
            },

            afterClose: function () {
                t.state.status = 'idle';
                vars.afterClose && vars.afterClose.call(m);
                lockView.unlockView();
            }
        };

        m.open.call(m, conf);
        return this;
    },

    close: function () {
        m.close.call(m);
    }
};

export default modal;
module.exports = modal;
