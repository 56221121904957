/**
 * Created by havard on 06.03.2017.
 */

import Events from "./Events";
import {toArray, loadImages, closestNumIn} from "../utils";
import {C} from '../J';

var RevealingGallery = function (element, vars) {

    vars = vars || {};
    var defaults = {
        key: 'val'
    };

    var elConf = {};
    if (element.dataset.proto) {
        elConf = JSON.parse(element.dataset.proto);
    }

    this.vars = {
        ...defaults,
        ...vars,
        ...elConf
    };

    this.rootElement = element;
    element.RevealingGallery = this;
    this.constructor.instances.push(this);

    this.onChange = vars.onChange || function () {
    };
    this.events = new Events;

    this.init();
};

RevealingGallery.prototype = {

    constructor: RevealingGallery,

    init: async function () {

        var t = this;

        var images = toArray(this.rootElement.getElementsByTagName('img'));

        this.rgWrap = document.createElement('div').AddClass('rg-wrap');
        this.items = toArray(this.rootElement.getElementsByClassName('rg-item'));
        this.activeIndex = 0;
        this.active = this.items[this.activeIndex];

        var len = this.len = this.items.length;
        var clonesLen = this.clonesLen = Math.min(
            this.vars.clones || 2,
            len
        );

        var invalid = this.invalid = len < 2;

        if (invalid) {
            invalid && this.rootElement.AddClass('rg-invalid rg-initialized');
            this.events.fireEvent('change');
            return;
        }

        // Clone work

        var startClonesWrap = this.startClonesWrap = C('div', {'class': 'rg-clone-wrap rg-start-clones'}).Append(
            this.startClones = new Array(clonesLen).fill(undefined).map(function (v, i) {

                var item = this.items[i];
                var clone = item.cloneNode(true).AddClass('rg-item-clone');
                item.rgClones = item.rgClones || [];
                item.rgClones.push(clone);
                clone.originalItem = item;

                return clone;
            }, this)
        );

        var endClonesWrap = this.endClonesWrap = C('div', {'class': 'rg-clone-wrap rg-end-clones'}).Append(
            this.endClones = new Array(clonesLen).fill(undefined).map(function (v, i) {

                var item = this.items[len - 1 - i];
                var clone = item.cloneNode(true).AddClass('rg-item-clone');
                item.rgClones = item.rgClones || [];
                item.rgClones.push(clone);
                clone.originalItem = item;

                return clone;
            }, this).reverse()
        );

        this.startClone = this.startClones[0];
        this.endClone = this.endClones[clonesLen - 1];

        this.rgWrap.Append(
            endClonesWrap,
            this.items,
            startClonesWrap
        ).AppendTo(this.rootElement);

        for (var i = 0; i < len; i++) {
            this.items[i].rgItemIndex = i;
        }

        // Bind events

        this.boundRePosition = this.rePosition.bind(this);
        // this.boundClickHandler = this.clickHandler.bind(this);
        // this.rootElement.addEventListener('click', this.boundClickHandler);

        this.boundResizeHandler = this.resizeHandler.bind(this);
        addEventListener('resize', this.boundResizeHandler);

        this.boundResizeHandler();

        // Draggable ----------------------/

        var snapVals = [];

        function onDragStart() {
            var itm, itms = t.items;
            snapVals = [];

            for (var i = 0; i < itms.length; i++) {
                itm = itms[i];
                var val = -(itm.offsetLeft + ((itm.clientWidth - t.rootElement.clientWidth) / 2));
                val = Math.round(val);
                snapVals.push(
                    val
                )
            }

            t.rgWrap.classList.add('grabbing');
        }

        function onDragEnd() {

            var threshold = 60;
            var newIndex, newX, findX;

            if (t.throwEnd > (snapVals[0] + threshold)) {

                newX = -(t.rgWrap.clientWidth - this.x);
                findX = -(t.rgWrap.clientWidth - t.throwEnd);

                newIndex = snapVals.indexOf(closestNumIn(findX, snapVals));

                t.draggable.tween.kill();

                t.goTo(newIndex, newX)

            } else if (t.throwEnd < (snapVals[snapVals.length - 1] - threshold)) {

                newX = t.rgWrap.clientWidth - Math.abs(this.x);
                findX = t.rgWrap.clientWidth - Math.abs(t.throwEnd);

                newIndex = snapVals.indexOf(closestNumIn(findX, snapVals));

                t.draggable.tween.kill();

                t.goTo(newIndex, newX);

            } else {

                newIndex = snapVals.indexOf(closestNumIn(this.endX, snapVals));
                t.activate(newIndex);
            }

            t.rgWrap.RemoveClass('grabbing');

        }

        this.draggable = Draggable.create(this.rgWrap, {
            type: 'x',
            throwProps: true,
            dragClickables: true,
            //force3D: true,
            zIndexBoost: false,
            minimumMovement: 10,
            edgeResistance: .7,
            minDuration: .2,
            cursor: 'grabbing',
            maxDuration: .6,
            allowContextMenu: true,
            snap: {
                x: function (endValue) {
                    t.throwEnd = endValue;
                    return closestNumIn(endValue, snapVals);
                }
            },
            onClick: function (e) {
                if (e.button) {
                    e.button === 2 && e.preventDefault();
                    if (e.button === 3) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                        return false;
                    }

                } else {
                    // e.preventDefault();
                    // e.target.click();
                }
            },
            onDragStart: onDragStart,
            //onDrag: onDrag,
            onDragEnd: onDragEnd
        })[0];

        await loadImages(images);

        this.rootElement.classList.add('rg-initialized');
        this.resizeHandler();
        this.goTo(this.active.rgItemIndex);
    },

    resizeHandler: function () {

        var width = this.items.reduce(function (a, b) {
            return a + b.getBoundingClientRect().width;
        }, 0).toFixed(0) + 'px';

        var startClonesWidth = this.startClones.reduce(function (a, b) {
            return a + b.originalItem.clientWidth;
        }, 0) + 'px';

        var endClonesWidth = this.endClones.reduce(function (a, b) {
            return a + b.originalItem.clientWidth;
        }, 0) + 'px';

        this.rgWrap.style.width = width;
        this.startClonesWrap.style.width = startClonesWidth;
        this.endClonesWrap.style.width = endClonesWidth;
    },

    activate: function (newIndex) {

        var t = this;

        var oldActive = this.active;
        var oldActiveIndex = this.activeIndex;

        if (oldActiveIndex == newIndex) {
            return;
        }

        this.active.classList.remove('active');
        this.active.rgClones && this.active.rgClones.forEach(function (itm) {
            itm.classList.remove('active');
        });

        this.activeIndex = newIndex;
        this.active = this.items[newIndex];

        this.active.classList.add('active');
        this.active.rgClones && this.active.rgClones.forEach(function (itm) {
            itm.classList.add('active');
        });

        this.onChange({
            activeIndex: newIndex,
            length: this.items.length
        });

        this.events.fireEvent('change', {
            oldActive: oldActive,
            oldActiveIndex: oldActiveIndex,
            active: this.active,
            activeIndex: this.activeIndex
        })
    },

    goTo: function (toIndex, fromX, skipAnimation) {

        var dur = .45;
        skipAnimation && (dur = 0);
        var ease;

        this.activate(toIndex);
        var active = this.active;

        var toOffset = -(active.offsetLeft + ((active.clientWidth - this.rootElement.clientWidth) / 2));

        if (fromX) {

            var vel = Math.abs(ThrowPropsPlugin.getVelocity(this.rgWrap, 'x'));

            if (vel > 0 && vel < 700) {

                ease = Power1.easeInOut;
                dur = dur * 1.3;
            } else if (vel > 2500) {

                ease = Power2.easeOut;
            } else {

                ease = Power1.easeOut;
            }

            if (this.draggable.tween) {
                this.draggable.tween.kill()
            }

            TweenLite.set(this.rgWrap, {
                x: fromX
            });

        } else {
            ease = Power1.easeOut;
        }

        TweenLite.to(this.rgWrap, dur, {
            ease: ease,
            x: Math.round(toOffset)
            // force3D: true
        });
    },

    next: function () {

        var fromX = this.rgWrap.clientWidth - Math.abs(this.draggable.target._gsTransform.x);

        var loop = this.activeIndex + 1 === this.items.length ? fromX : false;
        var newIndex = loop ? 0 : this.activeIndex + 1;
        this.goTo(newIndex, loop, true);
    },

    prev: function () {

        var fromX = this.rgWrap.clientWidth - this.draggable.target._gsTransform.x;

        var loop = this.activeIndex === 0 ? -fromX : false;
        var newIndex = loop ? this.items.length - 1 : this.activeIndex - 1;
        this.goTo(newIndex, loop, true);
    },

    rePosition: function () {
        var active = this.active;
        var toOffset = -(active.offsetLeft + ((active.clientWidth - this.rootElement.clientWidth) / 2));
        TweenLite.set(this.rgWrap, {
            x: toOffset
        })
    },

    destroy: function () {
        //remove handlers
        this.rootElement.RevealingGallery = null;
        delete this.rootElement.RevealingGallery;
        this.constructor.instances[
            this.constructor.instances.indexOf(this)
            ] = null;
    }

};

RevealingGallery.instances = [];

RevealingGallery.getInstance = function (el) {
    for (var i = 0; i < RevealingGallery.instances.length; i++) {
        var instance = RevealingGallery.instances[i];
        if (el == instance.rootElement) {
            return instance;
        }
    }
};

RevealingGallery.create = function (targets, vars) {

    var created = [];

    if (targets instanceof Element) {
        targets = [targets];
    } else if (targets instanceof HTMLCollection) {
        targets = toArray(targets);
    } else if (typeof targets == 'string') {
        targets = toArray(document.querySelectorAll(targets));
    } else if (!targets instanceof Array) {
        throw 'arguments[0] "targets" is invalid ' + targets + ' - must be Element, Array, HTMLCollection or query string';
    }

    for (var i = 0; i < targets.length; i++) {
        var target = targets[i];
        if (target.RevealingGallery) {
            continue;
        }
        created.push(new RevealingGallery(target, vars));
    }

    return created;
};

module.exports = RevealingGallery;
export default RevealingGallery;
