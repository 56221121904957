import {util_classNames} from "../utils/classNames";
import {C} from '../J';
import 'url-polyfill';

// Module is not self contained todo: create pager class

export default (vars) => {

    const {data, renderTo, onPageChange, pagerAction} = vars;
    const {page_param_name: pageParamName} = data;
    const state = {
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: 10,
        pagesPerGroup: 10,
        pageNumElements: [[]],
        pageSteppers: [[]],
        currentGroup: 1,
        numGroups: 1,
        pageUrl: '',
        currentPagesArray: []
    };

    const text = {
        next: data.page_next_text,
        prev: data.page_prev_text,
        page: data.page_text,
        pagePlural: data.page_text_plural,
        subject: data.subject_text,
        subjectPlural: data.subject_text_plural,
    };

    function createPageNumber(href, isActive, pageNum) {
        const classNames = util_classNames(
            'pager-pageNum',
            {'pager-pageNum_active': isActive}
        );

        const config = {
            'class': classNames,
            href
        };

        var element = C('a', config).Html(pageNum);
        element.pageIndex = parseInt(pageNum);

        element.addEventListener('click', clickHandler);

        return element;
    }

    function clickHandler(e) {

        e.preventDefault();
        const targetEl = e.currentTarget;

        if (!targetEl.pageIndex || targetEl.pageIndex === state.currentPage) {
            return;
        }






        state.pageUrl = targetEl.href;

        const url = (new URL(targetEl.href));

        const s = new URLSearchParams(url.search);
        s.append('type', 'json');

        if( !existsInCurrent(targetEl.pageIndex) ) {
            reRenderNumbers(targetEl.pageIndex);
        } else {
            updateCurrentPageNum(targetEl.pageIndex);
        }



        onPageChange({
            url: url.origin + (pagerAction || url.pathname) + '?' + s.toString(),
            documentUrl: url.href,
        })
    }

    function existsInCurrent(n)
    {
        return state.currentPagesArray.includes(parseInt(n));
    }

    function updateCurrentPageNum(pageNum) {


        const cp = state.currentPagesArray.indexOf(state.currentPage);
        const np = state.currentPagesArray.indexOf(pageNum);

        state.pageNumElements.forEach(elements => {
            elements[cp].RemoveClass('pager-pageNum_active');
            elements[np].AddClass('pager-pageNum_active');
        });

        updatePageSteppers(pageNum);
        state.currentPage = pageNum;
    }

    function createPageNumElements(pageObjects) {

        return pageObjects.map(o => {

            return createPageNumber(o.href, o.isActive, o.pageNum);
        })
    }

    function createPageStepper({nextText, prevText}) {

        return [
            C('a', {'class': 'pager-step pager-step_back'}).Text(prevText).Call(function () {
                this.addEventListener('click', clickHandler)
            }),
            C('a', {'class': 'pager-step pager-step_forward'}).Text(nextText).Call(function () {
                this.addEventListener('click', clickHandler)
            }),
        ];
    }

    function setPageNumUrl(n)
    {
        const urlObject = new URL(location.href);
        const url = [urlObject.origin, urlObject.pathname].join('');
        const {search} = urlObject;
        const searchParams = new URLSearchParams(search);

        searchParams.set(pageParamName, String(n));
        return [url, searchParams.toString()].filter(v => v && v.length).join('?');
    }

    function updatePageSteppers(pageNum) {

        const lastPage = Math.ceil(state.totalPages/state.itemsPerPage);
        const prevPage = pageNum-1 < 1 ? 1 : pageNum-1;
        const nextPage = pageNum+1 >= lastPage ? lastPage : pageNum+1;

        const nextUrl = setPageNumUrl(nextPage);
        const prevUrl = setPageNumUrl(prevPage);


        state.pageSteppers.forEach(stepper => {

            const {next, prev} = stepper;

            next.href = nextUrl;
            next.pageIndex = parseInt(nextPage);
            prev.href = prevUrl;
            prev.pageIndex = parseInt(prevPage);

            if( prevPage === pageNum ) {
                prev.AddClass('pager-step_disabled');
            } else {
                prev.RemoveClass('pager-step_disabled');
            }
            if( nextPage >= lastPage+1 ) {
                next.AddClass('pager-step_disabled');
            } else {
                next.RemoveClass('pager-step_disabled');
            }
        });
    }


    function createPagesArray(urlObject) {

        const url = [urlObject.origin, urlObject.pathname].join('');
        const {search} = urlObject;
        const searchParams = new URLSearchParams(search);

        const calcPages = Math.ceil(state.totalPages/state.itemsPerPage);
        //const pageArray = Array.apply(null, Array(calcPages)).map(function (x, i) { return i+1; });
        const pageArray = state.totalPages > 0 ? Array.apply(null, Array(calcPages)).map(function (x, i) { return i+1; }) : [];
        const groupsize = pageArray.length <= parseInt(state.pagesPerGroup) ? pageArray.length : parseInt(state.pagesPerGroup);
        const numGroups = pageArray.length <= parseInt(state.pagesPerGroup) ? 1 : Math.ceil(calcPages/state.pagesPerGroup);

        const currentGroup = Math.ceil(state.currentPage/groupsize)-1;
        const startIndex  = currentGroup*groupsize;
        const endIndex = startIndex+groupsize;
        const currentPagesArray = pageArray.slice(startIndex, endIndex);

        const retArray = [];

        currentPagesArray.forEach( (n, i) => {
            const num = n;
            !i ? searchParams.delete(pageParamName) : searchParams.set(pageParamName, String(num));
                const href = [url, searchParams.toString()]
                        .filter(v => v && v.length)
                        .join('?');

                const isActive = num == state.currentPage;

            retArray.push({
                href,
                isActive,
                pageNum: num
            })

        });

        state.currentPagesArray = currentPagesArray;
        state.currentGroup = currentGroup;
        state.numGroups = numGroups;

        return retArray;
    }

    function createPageGroupElements(pages, urlObject)
    {
        const currentGrp = state.currentGroup+1;
        const firstPage = pages[0]?.pageNum-1 || 1;
        const lastPage = pages[pages.length - 1]?.pageNum+1 || 1;

        let prev, next;

        if( currentGrp > 1 ) {
            const prevUrl = setPageNumUrl(firstPage);
            prev = C('a', { 'class': 'pager-pageNum pager-group-prev', href: prevUrl }).Html('..');
            prev.pageIndex = parseInt(firstPage);
            prev.addEventListener('click', clickHandler);
        }

        if( currentGrp !== state.numGroups && state.numGroups > 1  ) {
            const nextUrl = setPageNumUrl(lastPage);
            next = C('a', { 'class': 'pager-pageNum pager-group-next', href: nextUrl }).Html('...');
            next.pageIndex = parseInt(lastPage);
            next.addEventListener('click', clickHandler);
        }

        return {
            prevGroup: prev,
            nextGroup: next
        }
    }

    function reRenderNumbers(n)
    {
        state.currentPage = parseInt(n);
        const pages = createPagesArray(new URL(location.href));
        state.pageNumElements = [];

        renderTo.forEach(element => {
            const elm = element.querySelector('.pager-pageNumbers');

            const pageNumElements = createPageNumElements(pages);
            const { prevGroup, nextGroup } = createPageGroupElements(pages, new URL(location.href));
            const pg = [];

            if( prevGroup ) {
                pg.push(prevGroup);
            }

            state.pageNumElements.push(pageNumElements);

            pageNumElements.map( (n,i) => {
                pg.push(n);
            })


            if( nextGroup ) {
                pg.push(nextGroup);
            }

            elm.Html('').Append(pg);


        });
        updatePageSteppers(state.currentPage);
    }

    function render(data) {
        console.log(data);
        const {
            pages_total: totalPages,
            page_current: currentPage,
            items_per_page: itemsPerPage,
            pages_per_group: pagesPerGroup } = data;

        state.currentPage = parseInt(currentPage);
        state.totalPages = parseInt(totalPages);
        state.itemsPerPage = parseInt(itemsPerPage);
        state.pagesPerGroup = parseInt(pagesPerGroup);

        if (state.totalPages === 0) {
          state.currentGroup = -1;
          reRenderNumbers(1);
          return;
        }

        const pages = createPagesArray(new URL(location.href));
        state.pageNumElements = [];
        state.pageSteppers = [];

        renderTo.forEach(element => {

            const pageNumElements = createPageNumElements(pages);
            const { prevGroup, nextGroup } = createPageGroupElements(pages, new URL(location.href));
            const pg = [];


            const pageStepper = createPageStepper({
                nextText: text.next,
                prevText: text.prev
            });

            if( prevGroup ) {
                pg.push(prevGroup);
            }

            state.pageNumElements.push(pageNumElements);
            pageNumElements.map( (n,i) => {
                pg.push(n);
            })


            if( nextGroup ) {
                pg.push(nextGroup);
            }

            state.pageSteppers.push({
                prev: pageStepper[0],
                next: pageStepper[1]
            });

            element.Html('').Append(
                C('nav', {'class': 'pager-pageNav'}, [
                  C('div', {'class': 'pager-pageSteppers'}, pageStepper),
                  C('div', {'class': 'pager-pageNumbers'}, pg)
                ])
            );
        });

        updatePageSteppers(currentPage);
    }

    try {
        render(data);
    } catch (err) {
        console.error(err);
    }

    return {
        vars,
        render,
        state
    };
};
