import List from '../components/dynamicList';
import request from 'superagent';
import Filter from './filter2.0';
import Pager from './pager';
import merge from 'lodash.merge';
import {Q, C} from '../J';

var getJson = url => request
    .get(url || '?')
    .set('Accept', 'application/json');

const listComposer = (token) => {

    const {element, data} = token;
    const {filterSelector, listSelector, context, pager_action: pagerAction} = data;

    const listElement = listSelector && element.Q(listSelector);
    const filterElement = filterSelector && element.Q(filterSelector);
    const filterData = filterElement && JSON.parse(filterElement.dataset.filter);

    const totalRes = document.querySelector('.listComposer-total-results');

    let listReq,
        pagerContainers = [];

    if (!filterElement) {
        return;
    }

    const state = {
        filterUrl: ''
    };


    // Create pager containers
    let list;
    switch (context) {

        case 'cpList':

            list = Q('#cpList') || Q('#productList');
            pagerContainers = [
                C('div', {'class': 'pager pager_cpList pager_top'}).InsertBefore(list),
                C('div', {'class': 'pager pager_cpList pager_bottom'}).InsertAfter(list)
            ];
            break;

        case 'publicItemList':

            list = Q('#itemsList');
            pagerContainers = [
                // C('div', {'class': 'pager pager_cpList pager_top'}).InsertBefore(list),
                C('div', {'class': 'pager pager_cpList pager_bottom'}).InsertAfter(list)
            ];
            break;

        default:
            console.warn(`no list composer strategy for context: ${context}`);
            return;
    }


    // Create components
    const artList = new List(listElement);

    const filter = Filter({
        element: filterElement,
        data: filterData
    });

    const pager = pagerContainers.length && Pager({
        renderTo: pagerContainers,
        pagerAction,
        data,
        onPageChange: handlePageChange,
    });

    state.pageUrl = pager.state.activePageUrl;


    // Connect filter
    filter.events.addListener('change', handleFilterChange);


    // Handle changes
    function replaceHistory(url, state = {}) {
        history.replaceState(
            merge({}, history.state, state),
            document.title,
            url
        );
    }

    function handlePageChange({url, documentUrl}) {

        handleFetch(url, {
            renderPager: false
        });

        replaceHistory(documentUrl)
    }

    function handleFilterChange({filterPath, documentUrl}) {

        handleFetch(filterPath, {
            renderPager: true
        });

        replaceHistory(documentUrl)
    }

    function handleFetch(url, {renderPager}) {

        listReq && listReq.abort && listReq.abort();
        listReq = getJson(url);

        const listPromise = listReq
            .then(res => {
                if( totalRes ) {
                    totalRes.innerText = res.body.pages_total;
                }

                pager && renderPager && pager.render(res.body);
                return res.body.list;
            })
            .catch(err => {
                console.error(err)
            });

        artList.promiseUpdateHTML(listPromise);
    }


    return token;
};

module.exports = listComposer;
export default listComposer;
