import React, {Component} from "react";

export default class ContentEditable extends Component {

    componentWillMount(a, b) {
        this.updateContentOnChange({
            target: {
                innerHTML: this.props.html || this.props.placeholder
            }
        })
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.html !== this.base.innerHTML;
    }

    static defaultProps = {
        placeholder: null,
    };

    emitChange = (e) => {

        const {type, target} = e;

        switch (type) {

            case 'blur':
                if (target.innerText === '') {
                    this.updateContentOnChange({
                        type: 'blur',
                        target: {
                            innerHTML: this.props.placeholder
                        }
                    })
                } else {
                    this.updateContentOnChange(e);
                }

                break;

            case 'input':
                this.updateContentOnChange(e);

                break;

            case 'focus':
                if (target.innerHTML === this.props.placeholder) {

                    this.updateContentOnChange({
                        type: 'focus',
                        target: {
                            innerHTML: ''
                        }
                    })
                }

                break;
        }
    };

    updateContentOnChange(e) {

        const html = e.target.innerHTML;
        if (this.props.onChange && html !== this.lastHtml) {

            this.props.onChange({
                type: e.type,
                target: {
                    name: this.props.name,
                    value: html
                }
            });
        }

        this.lastHtml = html;
    }

    handleFocus() {

    }

    handleBlur() {

    }

    render(props) {
        const {html, onChange, children, placeholder, ...restProps} = props;

        return <div {...restProps}
                    onInput={this.emitChange}
                    onBlur={this.emitChange}
                    onFocus={this.emitChange}
                    contentEditable
                    dangerouslySetInnerHTML={{__html: html}}
        />;
    }
};
