/** @jsx preact.h */
import './polyfills';
import './J';
import {ui} from './moduleHandler';
import Events from "./components/Events";
import catNav from "./modules/catNav";
import slidingItems from "./modules/slidingItems";
import promotedGallery from "./modules/promotedGallery";
import itemGallery from "./modules/itemGallery";
import listComposer from "./modules/listComposer";
import itemScrollNav from "./modules/itemScrollNav";
import toTop from "./modules/toTop";
import itemMap from "./modules/itemMap";
import video from "./modules/video";
import listFilter from "./modules/listFilter";
import msg from "./modules/msg";

history.replaceState(history.state || {}, document.title, location.href);
window.globalEvents = new Events;

ui.catNav = catNav;
ui.slidingItems = slidingItems;
ui.promotedGallery = promotedGallery;
ui.itemGallery = itemGallery;
ui.itemMap = itemMap;
ui.video = video;
ui.listComposer = listComposer;
ui.listFilter = listFilter;
ui.itemScrollNav = itemScrollNav;
ui.msg = msg;
ui.toTop = toTop;

document.querySelector('.toggle-mobile-nav').addEventListener('click', e => {
  console.log('toggle mobile nav');
  const mobileNav = document.querySelector('.mobile-nav');
  console.log(mobileNav.classList);
  mobileNav.classList.toggle('hidden');
  console.log(mobileNav.classList);
})
