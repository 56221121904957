import mq from "./mq";

var ScrollWatchers = function () {
    var that = this;
    this.watcherId = [];
    this.watcherStatus = [];
    this.watcherPosition = [];
    this.watcherVars = [];

    this.referancePos = window.scrollY;
    this.direction = 'init';
    this.height = window.outerHeight;

    this.scrollHandler = ScrollWatchers.prototype.scrollHandler.bind(this);
    window.addEventListener('scroll', this.scrollHandler);

    this.mediaHandler = ScrollWatchers.prototype.mediaHandler.bind(this);
    window.addEventListener('breakpoint', this.mediaHandler);

};

ScrollWatchers.prototype = new Array;

ScrollWatchers.prototype.scrollHandler = function () {
    //var a = window.performance.now();
    var tempPos = window.scrollY;
    this.height = window.outerHeight;
    this.direction = tempPos - this.referancePos < 0 ? 'up' : 'down';
    for (var i = 0; i < this.length; i++) {
        if (!this.watcherStatus[i]) continue;
        this[i](i);
    }
    this.referancePos = tempPos;
    //var b = window.performance.now();
    //console.log('time to loop and execute watchers: ', (b - a).toFixed(2))
};

ScrollWatchers.prototype.mediaHandlerFn = function (i) {
    var m = this.watcherVars[i].media;
    if (!m) return;
    var id = this.watcherId[i];

    switch (mq.state) {

        case 'small': {
            m != 'mediumAndBelow' && m != 'small' && m != 'all' ?
                this.disable(id) : this.enable(id);
            break;
        }
        case 'medium': {
            m != 'mediumAndBelow' && m != 'mediumAndUp' && m != 'medium' && m != 'all' ?
                this.disable(id) : this.enable(id);
            console.log('medium');
            break;
        }
        case 'large': {
            m != 'mediumAndUp' && m != 'large' && m != 'all' ?
                this.disable(id) : this.enable(id);
            break;
        }
    }
};

ScrollWatchers.prototype.mediaHandler = function () {
    for (var i = 0; i < this.length; i++) {
        this.mediaHandlerFn(i);
    }
};

ScrollWatchers.prototype.add = function (element, id, vars) {

    if (arguments.length < 3) {
        throw new Error('at least 3 arguments expected, but only ' + arguments.length + ' provided');
    }

    !vars ? vars = {} : false;
    !vars.type ? vars.type = '' : false;

    vars.onEnter = vars.onEnter || function () {
    };
    vars.onLeave = vars.onLeave || function () {
    };

    vars.element = element;
    this.watcherId.push(id);
    this.watcherStatus.push(true);
    this.watcherPosition.push('init');
    this.watcherVars.push(vars);

    element.watcher = this;

    switch (vars.type) {

        case 'range':
            this.push(function (i) {
                //var abovePos = element.offsetTop - ( vars.offset || 0 ),
                //    belowPos = element.offsetTop + element.clientHeight - ( vars.endOffset || 0 );
                if (window.scrollY < element.offsetTop - (vars.offset || 0)) {
                    // run once on leave from inside to above
                    if (this.watcherPosition[i] !== 'above' && this.watcherPosition[i] !== 'init') {
                        this.watcherPosition[i] = 'above';
                        vars.onLeave ? vars.onLeave(element, id) : false;
                        vars.onLeaveAbove ? vars.onLeaveAbove() : false;
                    }
                } else if (window.scrollY > element.offsetTop + element.clientHeight - (vars.endOffset || 0)) {
                    // run once on leave from inside to below
                    if (this.watcherPosition[i] !== 'below' && this.watcherPosition[i] !== 'init') {
                        this.watcherPosition[i] = 'below';
                        vars.onLeave ? vars.onLeave(element, id) : false;
                        vars.onLeaveBelow ? vars.onLeaveBelow() : false;
                    }
                } else {
                    // run while inside
                    if (vars.onCalc) {
                        var calc = parseFloat(((window.scrollY - (element.offsetTop - (vars.offset || 0))) / element.clientHeight * 100).toFixed(2));
                        vars.onCalc(calc, element);
                    }
                    // run once on enter inside regardless of prev state
                    if (this.watcherPosition[i] !== 'inside') {
                        this.watcherPosition[i] = 'inside';
                        vars.onEnter(element, id);
                    }
                }
            });
            break;

        case  'active':
            this.push(function (i) {
                var rect = element.getBoundingClientRect(),
                    pos = this.watcherPosition[i],
                    pastTop = rect.top - vars.offset < 0,
                    pastBottom = rect.bottom - vars.offset < 0;

                if (pastTop && !pastBottom && pos !== 'inside') {
                    // console.log('inni');
                    vars.onEnter();
                    // element.addClass('inside');
                    this.watcherPosition[i] = 'inside';
                } else if (pastBottom && pos !== 'below') {
                    // console.log('under');

                    vars.onLeave();
                    // element.removeClass('inside');
                    this.watcherPosition[i] = 'below';
                } else if (!pastTop && pos == 'inside') {
                    // console.log('over');

                    vars.onLeave();
                    // element.removeClass('inside');
                    this.watcherPosition[i] = 'above';
                }

            });

            break;

        case 'sticky':
            this.push(this.createSticky(element, vars));
            break;

        default:
            this.push(function (i) {
                element.getBoundingClientRect().top < vars.offset || 0 ? vars.onEnter() : false;
            });
    }

    this.mediaHandlerFn(this.length - 1);
};


ScrollWatchers.prototype.stickyFn = function (i) {

};

ScrollWatchers.prototype.createSticky = function (element, vars) {

    var that = this;

    var wrapper, offset, constrainElement, top,
        elementHeight, constrainHeight, constrainPos, stopPos;

    offset = vars.offset || 0;
    wrapper = wrapElement(element, 'div');
    wrapper.addClass('sticky-wrapper');

    element.stuck = false;
    element.stopped = false;
    element.constrainElement = vars.constrain || false;
    element.stickyWrapper = wrapper;
    element.onStick = vars.onStick || function () {
    };
    element.onUnStick = vars.onUnStick || function () {
    };
    element.toggleSticky = ScrollWatchers.prototype.toggleSticky.bind(element);

    constrainElement = element.constrainElement;

    if (vars.adjustWidth) {
        function adjustWidth() {
            TweenLite.set(element, {
                width: wrapper.clientWidth
            });
        }

        window.addEventListener('resize', adjustWidth);
        adjustWidth();
    }

    return function (i) {

        //calculate
        top = wrapper.getBoundingClientRect().top;
        elementHeight = element.clientHeight;

        if (constrainElement) {
            constrainHeight = constrainElement.clientHeight;
            if (elementHeight > constrainHeight - 100) {
                return;
            }
            constrainPos = constrainElement.getBoundingClientRect().bottom;
            stopPos = constrainPos - elementHeight;
        } else {
            stopPos = Infinity;
        }

        //evaluate, then do
        if (top < offset && !element.stuck && stopPos > 0) {
            //stick
            element.toggleSticky('stick', i);
        } else if (top > offset && (element.stuck || element.stopped)) {
            //unStick
            element.toggleSticky('unStick', i);
        } else if (!element.stopped && stopPos < 0) {
            //stop
            element.toggleSticky('stop', i);

        }

    };
};

ScrollWatchers.prototype.toggleSticky = function (action, i) {

    //this bound to sticky element
    switch (action) {
        case 'stick':
            this.stickyWrapper.style.height = this.clientHeight + 'px';
            this.addClass('stuck');
            this.removeClass('stopped');
            this.stuck = true;
            this.stopped = false;
            this.onStick(this, i);
            break;

        case 'unStick':
            this.stickyWrapper.style.height = 'auto';
            this.removeClass('stuck');
            this.removeClass('stopped');
            this.stuck = false;
            this.onUnStick(this, i);
            break;

        case 'stop':
            this.stuck = false;
            this.stopped = true;
            this.removeClass('stuck');
            this.addClass('stopped');
    }

};

ScrollWatchers.prototype.disable = function (id) {
    var pos = this.watcherId.indexOf(id),
        el = this.watcherVars[pos].element;

    this.watcherStatus[pos] = false;
    if (el.stuck) {
        el.toggleSticky('unStick')
    }

};
ScrollWatchers.prototype.enable = function (id) {
    this.watcherStatus[this.watcherId.indexOf(id)] = true;
};
ScrollWatchers.prototype.kill = function (id) {
    var i = this.watcherId.indexOf(id);
    this.splice(i, 1);
    this.watcherId.splice(i, 1);
    this.watcherStatus.splice(i, 1);
    this.watcherPosition.splice(i, 1);
};
ScrollWatchers.prototype.killAll = function () {
    this.splice(0, this.length);
    this.watcherId = [];
    this.watcherStatus = [];
    this.watcherPosition = [];
};

function percentRange(start, stop, initial) {
    if (initial < start) {
        return 0
    }
    if (initial > stop) {
        return 100
    }
    var total = (-start) - (-stop);
    console.log(total);
    return (initial - start) / total * 100;
}

var scrollWatchers;

function getScrollWatchers() {
    return scrollWatchers = scrollWatchers || new ScrollWatchers;
}

exports.getInstance = getScrollWatchers;

export default {
    getInstance: getScrollWatchers
};


