export const todo = console.log.bind(null, 'TODO: ');

export const prop = propName => object => object[propName] || null;
export const tap = fn => value => {
  fn(value);
  return value;
};

export const singular = (n, yes, no) => n === 1 && yes || no;

/**
 *
 * @param selector {string}
 * @param  hitFn {function(UIEvent, HTMLElement):void}
 * @param missFn {function(UIEvent):void}
 * @returns {function(*=): void}
 */
export const withSelectorCallback = (selector, hitFn, missFn = null) => (e) => {

  const target = (e.target.matches(selector) && e.target) || e.target.closest(selector);

  target && hitFn && hitFn(e, target);
  !target && missFn && missFn(e)
}

export const roundCapped = (decimals, number) => Math.round(number * 10 ** decimals) / 10 ** decimals;

export const toArray = (iterable) => {
    for (var a = [], l = iterable.length; l--;) a[l] = iterable[l];
    return a;
};

export const noop = () => {
};
export const logArgs = function (...args) {
    args.forEach(arg => console.log(arg))
};

export const noopReturn = (value) => value;

export const isElement = (el, targetEl, d) => {

    !d ? d = 8 : false;
    if (el == targetEl)
        return el;

    for (var i = 0; i < d; i++) {

        if (!el)
            return false;
        if (el == targetEl)
            return el;

        el = el.parentElement;
    }
};

export const isElementIn = (el, collection, depth = 4) => {

    let cLen = collection.length;

    //loop collection for first level
    for (let i = 0; i < cLen; i++) {
        if (el === collection[i])
            return el;
    }

    // for each level in depth
    for (let j = 0; j < depth; j++) {
        el = el.parentElement;
        if (!el) {
            return false;
        }

        //loop collection for nth level
        for (let k = 0; k < cLen; k++) {
            if (el === collection[k]) {
                return el;
            }
        }
    }
};

export const elementWithinSelector = (el, s) => {

    while (!el.matches(s)) {
        el = el.parentElement;
        if (el == null) return false;
    }

    return el;
};

export const closestNumIn = (num, arr) => {
    var curr = arr[0];
    var diff = Math.abs(num - curr);
    for (var val = 0; val < arr.length; val++) {
        var newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
        }
    }
    return curr;
};

export const elementWithinClass = (el, className) => {
    while (el && !el.classList.contains(className)) {
        el = el.parentElement;
    }
    return el;
};

export const preventPageBleed = (el, {
  side = 'right',
  offset = 0,
  width = null
}) => {

    el.style.removeProperty('margin-left');
    el.style.removeProperty('margin-top');

    if (!el instanceof isElement) {
        el = document.querySelector(el);
    }

    const rect = el.getBoundingClientRect();
    const pageWidth = width || window.innerWidth;

    switch (side) {

        case 'right':

            if (rect.right + offset > pageWidth) {
                //el.style.marginLeft = - ((rect.right - (pageWidth - globals.scrollbarWidth)) + offset) + 'px';
                el.style.marginLeft = -((rect.right - (pageWidth)) + offset) + 'px';
            }
            break;

        case 'left':

            if (rect.left - offset < 0) {
                el.style.marginLeft = -((rect.right - pageWidth) + vars.offset) + 'px'
            }
            break;

    }
};

export const getScrollbarWidth = () => {

    var el = document.createElement('div');
    el.style.position = 'fixed';
    el.style.overflow = 'scroll';
    el.style.width = '100px';
    el.style.height = '100px';

    //just to be x-browser safe
    var child = document.createElement('div');
    child.style.width = '120px';
    child.style.height = '120px';
    el.appendChild(child);

    document.body.appendChild(el);
    var sw = el.getBoundingClientRect().width - el.clientWidth;
    el.remove();

    return sw;
};

export const importScript = (src) => {

    return new Promise(function (resolve, reject) {

        var script = document.createElement("script");
        script.type = "text\/javascript";
        script.AppendTo(document.querySelector('header'));

        script.onload = function () {
            resolve(true)
        };

        script.onerror = function (err) {
            reject(err)
        };

        script.src = src;
    });
};

export const throttle = function (fn, time, thisArg) {

    thisArg = thisArg || window;
    fn = fn.bind(thisArg);

    var timer;

    return function () {
        clearTimeout(timer);
        timer = setTimeout(fn, time);
    }
};

export const loadImage = (url, callback, errorCallback) => {
    var img = new Image();
    img.onload = callback;
    img.onerror = errorCallback;
    img.src = url;
};

export const preloadImage = (url) => {

    return new Promise((resolve, reject) => {

        var img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
    })
};

export const loadImages = (images, extraTime = 0) => {

    let loaded = 0;
    let done = false;

    const complete = (resolve) => {

        setTimeout(() => {

            resolve(images);
        }, extraTime)
    };


    return new Promise((resolve, reject) => {

        if (!images || !images.length) {
            return complete(resolve);
        }

        for (var i = 0; i < images.length; i++) {

            var img = images[i];

            img.complete
            && (loaded++)
            || (images[i].onload = () => {
                loaded++;
                (loaded === images.length) && complete(resolve);
            });

            (loaded === images.length) && complete(resolve);
        }
    });
};
