/**
 * Created by havard on 16.01.2018.
 */

import Events from "./Events";
import {C} from '../J';

var JModal = function (vars) {

    vars = vars || {};

    var defaults = {
        template: this.constructor.templates.default,
        animate: this.constructor.animate.default
    };

    this.vars = vars = {
        ...defaults,
        ...vars,
    };

    this.template = vars.template;
    this.animate = vars.animate;

    this.constructor.instances.push(this);
    this.init();

};

JModal.prototype = {

    constructor: JModal,

    init: function () {

        this.events = new Events;

        this.boundClickHandler = this.clickHandler.bind(this);
        this.boundKeyupHandler = this.keyupHandler.bind(this);

        this.dom = {};
        this.rootElement = this.template(this.dom);

        this.dom.closeBtn.addEventListener('click', this.close.bind(this));

        this.dom.all = (function (t) {

            var ret = [];
            for (var d in t.dom) {
                ret.push(t.dom[d])
            }
            return ret;
        })(this);
    },

    clickHandler: function (e) {

        var clicked = e.target;
        if (clicked == this.dom.outer) {
            this.close();
        }
    },

    keyupHandler: function (e) {

        if (!this.status == 'open') return;

        switch (e.keyCode) {

            case 27:
                this.close();
                break;
        }
    },

    setContent: function (content) {

        var target = this.dom.content;
        target.InnerHTML = '';

        if (content instanceof Element) {

            target.Append(content);
        } else if (typeof content == 'string') {

            target.InnerHTML = content;
        }

        return this;
    },

    setClass: function (domElement, className) {

        this.dom[domElement].AddClass(className);

        var o = this.classNamesAdded = this.classNamesAdded || {};
        var e = o[domElement] = o[domElement] || [];
        e.push(className);

        return this;
    },

    open: function (aVars) {

        addEventListener('keyup', this.boundKeyupHandler);
        addEventListener('click', this.boundClickHandler);

        if (this.status == 'open') return;

        var V = this.aVars = aVars || {};

        if (V.AddClass) {
            this.dom.outer.AddClass(aVars.AddClass);
        }

        V.beforeOpen = V.beforeOpen || noop;
        V.afterOpen = V.afterOpen || noop;
        V.beforeClose = V.beforeClose || noop;
        V.afterClose = V.afterClose || noop;

        this.animate.call(this, 'open');
        this.status = 'open';

        return this;
        // this.aVars.onOpen.call(this);
    },

    close: function () {

        removeEventListener('keyup', this.boundKeyupHandler);
        removeEventListener('click', this.boundClickHandler);

        if (this.status == 'closed') return;
        this.animate.call(this, 'close');
        this.status = 'closed';
    },

    destroy: function () {

        removeEventListener('keyup', this.boundKeyupHandler);
        removeEventListener('click', this.boundClickHandler);
    }

};

JModal.instances = [];

JModal.getInstance = function (el) {

    for (var i = 0; i < JModal.instances.length; i++) {
        var instance = JModal.instances[i];
        if (el == instance.rootElement) {
            return instance;
        }
    }
};

JModal.templates = {

    'default': function (dom) {

        return C('div', {'class': 'modal-outer'}).Append(
            C('div', {'class': 'modal-inner'}).Append(
                C('a', {'class': 'modal-close'}).Cache(dom, 'closeBtn'),
                C('div', {'class': 'modal-content'}).Cache(dom, 'content')
            ).Cache(dom, 'inner')
        ).Cache(dom, 'outer')
    },

    'big': function (dom) {

        return C('div', {'class': 'modal-outer'}).Append(
            C('a', {'class': 'modal-close'}).Cache(dom, 'closeBtn'),
            C('div', {'class': 'modal-inner'}).Append(
                C('div', {'class': 'modal-content'}).Cache(dom, 'content')
            ).Cache(dom, 'inner')
        ).Cache(dom, 'outer')
    }
};

JModal.animate = {

    'default': function (action) {

        var t = this, D = t.dom, V = t.aVars, tw = new TimelineMax;

        switch (action) {

            case 'open':

                V.beforeOpen.call(t);
                D.outer.PrependTo(document.body);
                tw.eventCallback('onComplete', function () {
                    V.afterOpen.call(t)
                });

                tw.set([D.outer, D.inner], {opacity: 0});
                tw.set(D.inner, {y: 100, scale: 1.1});

                tw.to(D.outer, .25, {opacity: 1, force3D: true})
                    .to(D.inner, .45, {y: 0, opacity: 1, scale: 1, force3D: true, ease: Power2.easeOut}, '-=.1')
                    .set(D.all, {clearProps: 'all'});

                break;

            case 'close':

                V.beforeClose.call(t);
                tw.eventCallback('onComplete', function () {

                    if (V.AddClass)
                        D.outer.RemoveClass(V.AddClass);

                    V.afterClose.call(t);
                    D.outer.Remove();

                    var ca;
                    if (ca = t.classNamesAdded) {

                        for (var d in ca) {
                            ca[d].forEach(function (c) {
                                t.dom[d].RemoveClass(c)
                            })
                        }

                        delete t.classNamesAdded;
                    }

                    delete t.aVars;
                });

                tw.to(D.inner, .3, {scale: .8, opacity: 0, ease: Back.easeIn})
                    .to(D.outer, .6, {opacity: 0})
                    .set(D.all, {clearProps: 'all'});

                break;
        }
    }
};

module.exports = JModal;
export default JModal;
