const TypeFilter = function (input, vars) {

  this.vars = vars || {};
  this.input = input;
  this.config = {
    type: 'list',
    startAt: 0,
    returnMax: 300,
    ...vars
  };

  this.ignorePredicate = vars.ignorePredicate || function () {
    return false;
  };

  input.TypeFilter = this;

  this.init();
};

TypeFilter.prototype = {

  constructor: TypeFilter,

  init: function () {

    var t = this;
    this.rootElement = document.querySelector('[data-typefiltertarget=' + this.config.target + ']');

    if (!this.rootElement) {
      console.log('ERROR - fix please: typefilterTarget [data-typefiltertarget=' + this.config.target + '] not found');
      return;
    }

    this.input.onkeydown = function (e) {

      if (e.which == 13) {
        e.preventDefault();
      }
    };

    this.input.onkeyup = this.filter.bind(this);
    this.input.onchange = this.filter.bind(this);

    var observerConfig = {childList: true};

    this.observer = new MutationObserver(function (o) {

      t.update();
      t.filter();
    });

    this.update();
    this.observer.observe(this.rootElement, observerConfig);
    this.filter('');

  },

  update: function () {

    this.itemTargets = this.rootElement.QA(this.config.itemTargets + ":not(.type-filter-ignore)");

    this.itemValues = this.config.itemValues
                      ? this.rootElement.QA(this.config.itemValues)
                      : null;
  },

  onType: function () {
    //var value = this.input.value;
    //if(value.length > this.config.min) {
    //
    //}
  },

  filter: function (value) {

    var targets = this.itemTargets;
    var values = this.itemValues;
    var count = 0;

    if (this.itemValues && this.config.filterChecked) {

      targets = targets.filter(function (y) {
        if (!values[targets.indexOf(y)].checked) {
          return y
        } else {
          count++
        }
      }, this);
    }

    for (var i = 0; i < targets.length; i++) {

      var el = targets[i],
        val = this.input.value.toLowerCase(),
        match = el.textContent.toLowerCase(),
        len = val.length;

      if (this.ignorePredicate(el)) {

        el.style.display = 'none';
        continue;
      }

      if (len > this.config.startAt) {

        if (
          match.indexOf(val) > -1 &&
          count < this.config.returnMax
        ) {
          el.style.removeProperty('display');
          count++
        } else {
          el.style.display = 'none';
        }
      } else {

        if (count < this.config.returnMax) {

          el.style.removeProperty('display');
          count++
        } else {
          el.style.display = 'none';
        }
      }
    }

  }
};

TypeFilter.create = function (inputs, config) {
  if (inputs instanceof HTMLCollection) {
    inputs = toArray(inputs);
  } else if (typeof inputs == 'string') {
    inputs = toArray(document.querySelectorAll(inputs));
  } else if (inputs instanceof Element) {
    inputs = [inputs];
  } else if (!inputs instanceof Array) {
    throw 'argument[0] "inputs" is invalid ' + inputs + ' - must be Array, HTMLCollection or query string';
  }

  for (var i = 0; i < inputs.length; i++) {
    var input = inputs[i];
    var vars;

    input.eppo = input.eppo || {};
    if (input.eppo.typeFilter) {
      continue;
    }

    vars = input.dataset.typefilter || {};

    if (typeof vars == 'string') {
      vars = JSON.parse(vars);
    }
    if (config) {
      for (var conf in config) {
        vars[conf] = config[conf];
      }
    }
    new TypeFilter(input, vars)
  }
};

module.exports = TypeFilter;
export default TypeFilter;
