import revolverGallery from "./revolverGallery";

export default (token) => {

    token.vars = {
        captions: false,
        clones: 3
    };

    return revolverGallery(token);
};