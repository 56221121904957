/**
 * Created by havard on 15.02.2018.
 */


var Events = function (thisArg) {
    this.t = thisArg;
    this.handlers = {
        //'someEvent': [fn, fn, fn]
    };
    this.counter = 0;
};

Events.prototype = {

    constructor: Events,

    addListener: function (eventLabel, fn) {

        var id = 'fn' + (this.counter++);
        if (!this.handlers[eventLabel]) {
            this.handlers[eventLabel] = [];
        }
        this.handlers[eventLabel].push(fn);
        return fn
    },

    removeListener: function (eventLabel, fn) {

        var arr = this.handlers[eventLabel];
        var index = arr.indexOf(fn);
        index > -1 ? arr.splice(index, 1) : false;
    },

    fireEvent: function (eventLabel) {

        var args = [].slice.call(arguments).splice(1, arguments.length);

        for (var fn in this.handlers[eventLabel]) {

            if (!this.handlers[eventLabel].hasOwnProperty(fn)) continue;
            this.handlers[eventLabel][fn].apply(window, args)
        }
    }
};

module.exports = Events;

export default Events;
