import Events from "./Events";

const mq = {
    state: 'idle'
};

const events = new Events;

const breakPoints = [
    {
        name: 'small',
        get isMedia() {
            var w = window.outerWidth;
            return w < 721
        }
    }, {
        name: 'medium',
        get isMedia() {
            var w = window.outerWidth;
            return w > 720 && w < 961
        }
    }, {
        name: 'large',
        get isMedia() {
            var w = window.outerWidth;
            return w > 960
        }
    }
];

function evaluate() {
    for (var i = 0; i < breakPoints.length; i++) {

        var bp = breakPoints[i];

        if (mq.state !== bp.name && bp.isMedia) {
            mq.state = bp.name;
            events.fireEvent('change', {
                state: mq.state
            });
            break;
        }
    }
}

window.addEventListener('resize', evaluate);
window.addEventListener('DOMContentLoaded', evaluate);

events.evaluate = evaluate;

mq.addListener = events.addListener.bind(events);
mq.removeListener = events.removeListener.bind(events);
mq.init = evaluate;

export default mq;
module.exports = mq;

