import request from 'superagent';
import FormAssist from "../components/FormAssist";
import DropdownGroup from "../components/dropdownGroup";
import TypeFilter from "../components/typeFilter";
import EppoScroll from "../components/eppoScroll";
import Events from "../components/Events";
import {Q, C} from '../J';
import {toArray, isElementIn, preventPageBleed, getScrollbarWidth} from "../utils";
import {TweenMax} from "gsap";

export default (token) => {

    var root = token.element,
        vars = token.data,
        events = root.events = new Events,
        form = Q(vars.input),
        output = vars.output && document.querySelector(vars.output),
        f = new FormAssist(form),
        fEvents = f.events,
        dgEl = form.querySelector('.dropdown-group'),
        dg = new DropdownGroup(dgEl, {
            onActivateItem: activateDgItem
        }),
        actives = [],
        instance = {},
        dom,
        selectedParamName = vars.selectedParamName || 'filter';

    var latestQuery = location.search.replace('?', '');
    var latestGroupQuery = latestQuery;
    var scrollCandidates = toArray(dgEl.getElementsByClassName('eppo-scroll'));

    ////- cache dom
    dom = {
        form,
        summaryItems: C('div', {'class': 'filter-summary-items'})
    };

    ////- insert summaryElement
    dom.summaryItems.InsertAfter(dom.form);

    ////- create typeFilters
    TypeFilter.create(toArray(dgEl.getElementsByClassName('type-filter')));


    ////- create scroll
    EppoScroll.create(scrollCandidates);


    ////- connect form, module events and renderer
    connect();

    fChange(f, true).then(() => {
    });

    function connect() {

        fEvents.addListener('change', fChange);
        dom.summaryItems.addEventListener('click', clickHandler)
    }


    function clickHandler(e) {


        var fRemove = isElementIn(e.target, actives), connectedTo;

        if (fRemove) {

            connectedTo = document.getElementById(fRemove.filterConnection);

            switch (connectedTo.tagName) {

                case 'INPUT':
                    clearConnected(connectedTo);
                    break;

                case 'FIELDSET':
                    connectedTo.filterItems.forEach(function (item) {
                        item = document.getElementById(item);
                        clearConnected(item)
                    });
                    break;
            }

            // todo: refactor formAssist naming convention + add update method

            // var values = f.values;
            f.updateValues();
            f.events.fireEvent('change', f);
        }
    }

    function clearConnected(connectedTo) {
        switch (connectedTo.type) {

            case 'checkbox':
            case 'radio':
                connectedTo.TreeSelect && connectedTo.TreeSelect.unCheckBranch();
                connectedTo.checked = false;
                break;

            case 'text':
            case 'number':
                connectedTo.value = '';
                break;
        }
    }

    function renderGroup(g) {

        return C('div', {'class': 'summary-group'}).Append(
            g.map(function (v) {
                return renderValue(v);
            })
        );
    }

    function renderValue(v) {

        var isBool = (
            v.type == 'checkbox' ||
            v.type == 'radio'
        );

        var textRepresentation = (
            isBool && v.title || '"' + v.value + '"'
        );

        var ret = C('a', {class: 'summary-item'})
            // .Append(C('i', {class:'icon-cross'}))
            .Append(C('span').Html(textRepresentation))
            .Cache(actives)
            .Call(function () {
                this.filterConnection = v.id;
            });

        // when sub values, wrap value in grouping tag and render group to wrapper
        v.values && v.values.length &&
        (ret = C('div', {'class': 'item-has-group'}).Append(
            ret,
            renderGroup(v.values)
        ));

        return ret;
    }

    function renderSelection(e) {

        actives = [];
        var v = e.values,
            fragment = C('fragment'),
            groups = v.structure.groups,
            elements = v.structure.elements,
            i, len = groups.length;

        elements.forEach(function (v) {
            fragment.appendChild(
                C('div', {class: 'filter-summary-item-group'}).Append(
                    renderValue(v)
                )
            )
        });

        groups.forEach(function (g) {

            fragment.appendChild(
                C('div', {class: 'filter-summary-item-group'}).Append(
                    C('a', {class: 'summary-group-name'})

                        // .Append(C('i', {class: 'icon-cross'}))
                        .Append(C('span').Html(g.title))
                        .Cache(actives)
                        .Call(function () {

                            this.filterConnection = g.id;
                            document.getElementById(g.id).filterItems = g.values.map(function (v) {
                                return v.id
                            });
                        })
                ).Append(
                    renderGroup(g.values)
                )
            )
        });


        dom.summaryItems.Html('').Append(fragment);
    }

    async function fChange(e, suppressGet) {

        renderSelection(e);

        var query = f.toQueryString();
        var res, formData;
        if (query === latestQuery || suppressGet) return;

        latestQuery = query;
        var documentUrl = getDocumentUrl(query);

        history.replaceState(history.state || {}, document.title, documentUrl);

        // add param to query to prevent against history storing JSON response as actual page content
        var filterQuery = [query, 'type=json'].filter(function (s) {
            return s.length > 1
        }).join('&');

        var filterUrl = `${vars.action}?${filterQuery}`;

        output && TweenMax.to(output, .2, {opacity: 0});

        switch (vars.method) {

            case 'post':

                formData = queryStringToVendoFormData(query);

                res = await request
                    .post(vars.action)
                    .set('Accept', 'application/json')
                    .send(formData)
                    .then(res => res.body);

                var itemsList = res.find(function (res) {
                    return res.id == 'itemsList';
                });

                output.Html(itemsList.html && itemsList.html || '');

                break;

            default:

                res = await request
                    .get(filterUrl)
                    .set('Accept', 'application/json')
                    .then(res => res.body);
                console.log(res);

                events.fireEvent('mapData', res);
                output && output.Html(res.list);

                document.querySelectorAll('.paging-prev-link').forEach(function (el) {
                  const span = document.createElement('span');
                  span.classList = 'paging-prev-link';
                  span.Html('Forrige');
                  span.style = 'display:flex;border:1px solid #999;color:#999;border-radius:3px;padding:.2rem .5rem;';
                  el.replaceWith(span);
                });

                if (res.paging.pages == 1) {
                  document.querySelectorAll('.paging-next-link').forEach(function (el) {
                    const span = document.createElement('span');
                    span.classList = 'paging-next-link';
                    span.Html('Neste');
                    span.style = 'display:flex;border:1px solid #999;color:#999;border-radius:3px;padding:.2rem .5rem;';
                    el.replaceWith(span);
                  });
                } else {
                  document.querySelectorAll('.paging-next-link').forEach(function (el) {
                    const link = document.createElement('a');
                    link.classList = 'paging-next-link';
                    link.href = res.paging.nextLink;
                    link.Html('Neste');
                    link.style = 'display:flex;border:1px solid;border-radius:3px;padding:.2rem .5rem;';
                    el.replaceWith(link);

                  });
                }

                document.querySelectorAll('.current-page').forEach(function(el) {
                  el.Html(1);
                });
                document.querySelectorAll('.total-pages').forEach(function (el) {
                  el.Html(res.paging.pages);
                });
        }


        output && TweenMax.to(output, .2, {opacity: 1});
        // console.log('TODO: data-options for filter');
    }

    async function activateDgItem(e) {

        var content = e.active.Q('.dropdown-content'),
            toggle = e.active.Q('.dropdown-toggle');

        preventPageBleed(content, {
            offset: getScrollbarWidth()
        });

        var toggledName = toggle.dataset.filterName;
        var noFetch = toggle.dataset.filterNoFetch;

        if (noFetch)
            return;


        var query = f.toQueryString();
        latestGroupQuery = query;

        var filterQuery = [query, 'type=json', `${selectedParamName}=${toggledName}`].filter(function (s) {
            return s.length > 1
        }).join('&');

        var filterUrl = `${vars.action}?${filterQuery}`;

        scrollIntoView(content, toggle);

        var layer = addProtectiveDgLayer(content);
        var res, formData;

        switch (vars.method) {

            case 'post':

                formData = queryStringToVendoFormData(filterQuery);
                formData.append('filter', toggledName);

                res = await request
                    .post(vars.action)
                    .set('Accept', 'application/json')
                    .send(formData);

                var groupList = res.body.find(function (res) {
                    return res.id == 'filterGroupList';
                });

                content.Q('ul').Html(groupList && groupList.html || '');

                break;

            default:
                res = await request
                    .get(filterUrl)
                    .set('Accept', 'application/json');

                content.Q('ul').Html(res.body.list);
        }

        TweenMax.to(layer, .15, {
            opacity: 0, onComplete: function () {
                layer.remove()
            }
        });

        scrollIntoView(content, toggle);

        f.updateItems(content, toggle);
    }

    function addProtectiveDgLayer(content) {
        return C('div', {
            'class': 'dgProtect',
            style: 'background: rgba(255,255,255,.5); position: absolute; top: 0; left: 0; bottom: 0; right: 0;'
        }).AppendTo(content);
    }

    function scrollIntoView(content, toggle) {
        var b = content.getBoundingClientRect().bottom + scrollY;
        var t = toggle.getBoundingClientRect().top + scrollY;

        var destination = t + innerHeight < b ?
            t :
            b - innerHeight + 20;

        if (b > scrollY + innerHeight) {

            TweenMax.to(window, .6, {
                scrollTo: destination,
                ease: Power2.easeInOut
            })
        }
    }

};

//// FNs

function getDocumentUrl(query) {
    return [location.origin, location.pathname, `?${query}`].filter(function (s) {
        return s !== '?'
    }).join('');
}
