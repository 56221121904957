import EppoScroll from "../components/eppoScroll";
import MarkerClusterer from '@google/markerclusterer';
import enterView from 'enter-view';

import {C} from '../J';
import getMaps from "../components/maps/getMaps";

export default async (token) => {

    var root = token.element,
        vars = JSON.parse(root.dataset.itemMap),
        data = vars.data;

    var initialZoom = globalContext && globalContext.map && globalContext.map.defaultItemZoom || 18;

    var gMap = await getMaps();

    var google = gMap.google,
        SnazzyInfoWindow = gMap.SnazzyInfoWindow;

    ///// MAP

    var instanceType = root.dataset.instanceType || 'objectMap';

    var instance, map, markers, iw, infoWindow, mapData;

    function prepareMapData(items) {
        if (instanceType == 'objectMap') {
            return (function () {

                var item = items[0] || items,
                    ret = [];

                item.type = 'item';
                item.icon = globalContext.map.markers[item.category || 'undefined'];
                item.pos = item.pos || item.place.pos;
                ret.push(item);
                return ret;
            })();
        } else if (instanceType == 'artTrailMap') {
          return items.map(function(item) {

            item.type = 'item';
            item.pos = item.pos || item.place.pos;
            item.img = item.images.thumbnail;
            item.label = {
              text: item.label,
              color: 'white',
              fontWeight: 'bold'
            };
            item.title = item.name;
            item.body = '';
            item.link = item.href;
            return item;
          });
        } else if (instanceType == 'placeMap') {
          return (function () {

            var item = items[0] || items;
            var ret = [];
            var placePos = item.pos;

            item.type = 'item';
            item.pos = item.pos || placePos;
            item.body = '';
            item.icon = globalContext.map.markers['place'];

            ret.push(item);

            item.objects.map(function (object) {
              let obj = {};
              let objPos = undefined;
              try {
                objPos = object.attributes.latlng;
              } catch (e) {
                objPos = placePos;
              }
              obj.title = object.name;
              obj.body = '';
              obj.type = 'item';
              obj.img = object.img.mapthumb;
              obj.pos = objPos;
              ret.push(obj);
            });

            return ret;
          })();
        }

        var i, l = items.length, ret = {items: [], places: {}}, item;

        var arr = [];

        for (i = 0; i < l; i++) {

            item = items[i];
            if (item.place) {

                var place = item.place,
                    id = place.id;

                if (!ret.places[id]) {

                    place.type = "place";
                    place.icon = globalContext.map.markers['place'];
                    ret.places[id] = place;
                    ret.places[id].items = []
                }

                ret.places[id].items.push(item);

            } else {

                var icoName = item.category || 'undefined';
                item.type = "item";
                item.icon = globalContext.map.markers[icoName];
                ret.items.push(item);
                arr.push(item);
            }

        }

        var places = [];

        for (var p in ret.places) {

            places.push(ret.places[p]);
            arr.push(ret.places[p])
        }

        ret.places = places;

        //return arr;
        return ret;
    }

    ////


    function createMarkers(data) {
        var markers = [], i, len = data.length, item;

        var clusterStyles = (function () {

            var styles = [];
            var sizes = [
                [53, 52],
                [56, 55],
                [66, 65],
                [78, 77],
                [90, 89]
            ];

            for (var i = 2; i < 5; i++) {
                styles.push({
                    textColor: 'white',
                    textSize: 14,
                    fontFamily: "'Open Sans', Arial",
                    url: globalContext.map.markers.clusters + (i + 1) + '.png',
                    height: sizes[i][0],
                    width: sizes[i][1]
                })
            }

            return styles;
        })();

        for (i = 0; i < len; i++) {
            markers.push(createMarker(data[i]));
        }

        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath: globalContext.map.markers.clusters,
            styles: clusterStyles,
            averageCenter: true,
            maxZoom: 16
        });

        return markers;
    }

    function openInfoWindow(item) {
        infoWindow.setContent(gMap.renderInfoWindow[item.type](item));
        infoWindow.setPosition(item.pos);
        infoWindow.open();

        setTimeout(function () {
            EppoScroll.create('.iw-scroll');
        }, 200);
    }

    function createMarker(item) {
        var marker = item.marker = gMap.createMarker({
            position: item.pos,
            icon: item.icon,
            map: map,
            label: item.label ?? null
        });

        google.maps.event.addListener(marker, 'click', function (e) {
            openInfoWindow(item);
        });

        return marker;
    }


    ////

    function renderView(data) {
      mapData = prepareMapData(data);
      markers = createMarkers(mapData);
      gMap.fitToMarkers(map, markers);
    }

    ////


    //

    function createInstance(data) {
        var mapEl = C('div', {class: 'map'});

        map = gMap.createMap(mapEl);

        infoWindow = new SnazzyInfoWindow({
            map: map,
            panOnOpen: false
        });

        if (data) {
            renderView(data)
        }

        var API = {
            mapElement: mapEl,
            map: map,
            prepareMapData: prepareMapData,
            renderView: renderView,
            infoWindow: infoWindow,
            openInfoWindow: openInfoWindow,
            get markers() {
                return markers
            },
            mapData: mapData
        };

        mapEl.AppendTo(token.element);

        gMap.listenOnce(map, 'idle', function () {
            openInfoWindow(data);
            map.setZoom(data.zoom - 1 || initialZoom - 4);
        });

        return API
    }

    module.exports = createInstance(data || null);

};
