import {TweenMax} from "gsap";
import 'gsap/ScrollToPlugin';

export default (element, vars) => {

    //todo: add exceptions for in view content; add querySelector support; test/fix/fool proof for bottom edge- and window scroll

    vars = vars || {};
    var defaults = {
        container: window,
        duration: .4,
        ease: Power1.easeInOut,
        offset: 20,
        edge: 'top',
        autoKill: false
    };

    vars = {
        ...defaults,
        ...vars,
    };

    var scroll = vars.container.scrollTop || vars.container.scrollY;
    var innerHeight = vars.container.innerHeight || vars.container.clientHeight;

    var max = (function () {
        if (vars.container == window) {
            return document.body.scrollHeight - innerHeight;
        } else {
            return vars.container.scrollHeight - vars.container.clientHeight;
        }
    })();

    var edge = element.getBoundingClientRect()[vars.edge];

    edge = Math.min(
        Math.max(edge + scroll - vars.offset, 0),
        max
    );

    TweenMax.to(vars.container, vars.duration, {
        scrollTo: {
            y: edge,
            autoKill: vars.autoKill
        },
        ease: vars.ease
    });
}
