/**
 * Created by havard on 16.01.2018.
 */

var locked = 0,
    tempScrollTop;

function getViewportScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

const lockView = {

    lockView: function () {
        if (locked == 0) {
            tempScrollTop = (window.scrollY || window.pageYOffset);
            TweenLite.set(document.documentElement, {top: -tempScrollTop, paddingRight: getViewportScrollbarWidth()});
            document.documentElement.classList.add('locked-view');
        }
        locked++
    },

    unlockView: function () {
        if (locked == 1) {
            document.documentElement.classList.remove('locked-view');
            TweenLite.set(document.documentElement, {top: 0, paddingRight: ''});
            window.scrollTo(false, tempScrollTop);
        }
        locked--
    }
};

module.exports = lockView;
export default lockView;
