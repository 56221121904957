import revolverGallery from "./revolverGallery";

export default (token) => {

    token.vars = {
        captions: true,
        lightbox: true,
        clones: 3
    };

    return revolverGallery(token);
};
