/**
 * Created by havard on 10.10.2017.
 */

import ResizeListener from "./ResizeListener";
import {Draggable} from "gsap/Draggable";
import ThrowProps from '../gsap/ThrowPropsPlugin';
import {throttle, toArray, closestNumIn} from "../utils";
import Events from "./Events";

const SlidingItems = function (selector, vars) {

    const defaults = {
        snap: 'center',
        changeAt: 'center'
    };
    vars = vars || {};
    vars = Object.assign(defaults, vars);
    this.vars = vars;

    this.events = new Events;
    this.onChange = vars.onChange || function () {
    };
    this.rootElement = selector instanceof Element ? selector : document.querySelector(selector);
    this.init();
};

SlidingItems.prototype = {

    constructor: SlidingItems,

    init: function () {

        var t = this,
            initIndex;

        this.cacheDom();
        this.activeIndex = 0;

        initIndex = (function (items) {
            for (var i = 0; i < items.length; i++) {
                if (
                    items[i].classList.contains('active') ||
                    items[i].classList.contains('slit-active')
                ) {
                    return i
                }
            }
            return 0;
        })(t.items);

        this.active = this.items[initIndex];

        this.itemsLen = this.items.length;
        this.active = this.items[this.activeIndex];
        this.active.classList.add('slit-active');

        this.draggable = this.makeDraggable()[0];
        this.oldWidth = this.getTotalWidth();

        if (this.getTotalWidth() > this.getRootWidth()) {
            this.rootElement.classList.add('slit-overflow');
        } else {
            this.rootElement.classList.remove('slit-overflow');
        }

        this.changeActive(initIndex, 'initDuration' in this.vars ? this.vars.initDuration : .7);

        this.resizeHandler = throttle(this.resizeHandler, 30, this);

        addEventListener('resize', this.resizeHandler);

        ResizeListener.add({
            el: this.rootElement,
            cb: this.resizeHandler
        });

        this.resizeHandler();
    },

    clickHandler: function (event) {
        var item = isElementIn(event.target, this.items);
        if (item) {
            this.changeActive(this.items.indexOf(item))
        }
    },

    resizeHandler: function () {

        if (this.getTotalWidth() > this.getRootWidth()) {
            this.rootElement.classList.add('slit-overflow');
            this.draggable.enable();
            this.changeActive(this.activeIndex);
        } else {
            this.rootElement.classList.remove('slit-overflow');
            this.draggable.disable();
        }

        this.draggable.applyBounds(this.bounds);
    },

    cacheDom: function () {
        this.itemsWrapper = this.rootElement.getElementsByTagName('ul')[0];
        this.items = toArray(this.itemsWrapper.getElementsByTagName('li'));
    },

    getPages: function () {

        var pages = [],
            items = this.items,
            itemsLen = items.length,
            item, itemWidth,
            i, p = 0,
            rootWidth = this.getRootWidth(),
            wCount = 0;

        while (itemsLen--) {

            item = items[itemsLen];
            itemWidth = item.clientWidth;

            //increment wCount with itemWidth
            wCount += itemWidth;

            // if wCount exceeds rootWidth, increment page index
            if (wCount > rootWidth) {
                p++;
            }

            // if no page created for page index, create one and reset wCounter
            if (!pages[p]) {
                pages[p] = [];
                wCount = itemWidth;
            }

            // push item to current page;
            pages[p].push(item);

        }

        // reverse results

        for (i = 0; i < pages.length; i++) {
            pages[i].reverse();
        }
        pages.reverse();

        return pages;
    },

    getTotalWidth: function () {
        var w = 0;
        for (var i = 0; i < this.items.length; i++) {
            w += this.items[i].clientWidth;
        }
        return w
    },

    getActivePage: function (pages) {

        pages = pages || this.getPages();

        for (var i = 0; i < pages.length; i++) {

            var p = pages[i];
            var index = p.indexOf(this.active);
            if (index > -1) {
                return i;
            }
        }
    },

    getRootWidth: function () {
        return this.rootElement.clientWidth;
    },

    changeActive: function (index, duration = .5) {

        // if(index == this.activeIndex) return;
        this.items[this.activeIndex].classList.remove('slit-active');

        this.activeIndex = index;
        this.active = this.items[index];
        this.active.classList.add('slit-active');

        this.offsetItemsWrapper(index, {duration});
        this.onChange(index);

        this.events.fireEvent('change', {
            active: this.active,
            activeIndex: index
        })
    },

    goToPage: function (page, pages) {

        pages = pages || this.getPages();
        var newActiveIndex;

        switch (this.vars.changeAt) {

            case 'left':
                newActiveIndex = this.items.indexOf(pages[page][0]);
                this.changeActive(newActiveIndex);
                return {
                    activeItem: this.items[newActiveIndex],
                    activeIndex: newActiveIndex
                };

            default:
                console.log('implement center handling')
        }
    },

    offsetItemsWrapper: function (index, vars = {duration: .5}) {

        var dest = this.getSnapVals(this.vars.changeAt)[index];

        // let final destination be max 0 and no less than bounds.min
        dest = Math.min(
            0,
            Math.max(this.bounds.minX, dest)
        );

        TweenLite.to(this.itemsWrapper, vars.duration, {
            x: parseInt(dest),
            force3D: true,
            // delay:.1,
            ease: Power1.easeOut
        });
    },

    get bounds() {
        return {
            minX: Math.min(0, this.getRootWidth() - this.getTotalWidth()),
            maxX: 0
        }
    },

    getSnapVals: function (x) {

        var itm, itms = this.items,
            snapVals = [],
            i, val;

        switch (x) {

            case 'left':
                for (i = 0; i < itms.length; i++) {
                    itm = itms[i];
                    val = -(itm.offsetLeft);
                    // val = Math.round(val);
                    snapVals.push(Math.round(val))
                }

                break;
            default:
                for (i = 0; i < itms.length; i++) {
                    itm = itms[i];
                    val = -(itm.offsetLeft + ((itm.clientWidth - this.rootElement.clientWidth) / 2));
                    // val = Math.round(val);
                    snapVals.push(Math.round(val))
                }
        }

        return snapVals;
    },

    makeDraggable: function () {

        var tmpX = 0,
            lastX,
            dragging,
            snapVals = [],
            t = this,
            snap,
            endIndex;

        switch (this.vars.snap) {

            case 'center':
            case 'left':
                snap = {
                    x: function (endValue) {
                        return closestNumIn(endValue, snapVals);
                    }
                };
                break;

            default:
                snap = false;
        }

        return Draggable.create(this.itemsWrapper, {

            type: 'x',
            minimumMovement: 10,
            zIndexBoost: false,
            throwProps: true,
            throwResistance: 3000,
            force3D: true,
            overshootTolerance: .3,
            dragClickables: true,
            allowContextMenu: true,
            bounds: t.bounds,
            edgeResistance: .8,

            onDragStart: function () {
                snapVals = t.getSnapVals(t.vars.snap);
            },

            onClick: function (e) {

                if (e.button) {
                    if (e.button == 2) {
                        e.preventDefault();
                    }
                } else {
                    e.preventDefault();
                    e.target.click();
                }
            },

            snap: snap,

            onDragEnd: function () {

                var newActiveIndex;
                var snapIndex = snapVals.indexOf(closestNumIn(this.endX, snapVals)),
                    diff;

                diff = Math.abs(this.endX) - Math.abs(snapVals[snapIndex]);

                if (diff) {
                    newActiveIndex = snapIndex + 1
                } else {
                    newActiveIndex = snapIndex;
                }

                t.active.RemoveClass('slit-active');
                t.active = t.items[newActiveIndex];
                t.activeIndex = newActiveIndex;
                t.active.AddClass('slit-active');

                t.events.fireEvent('change', {
                    active: t.active,
                    oldActive: t.active,
                    activeIndex: newActiveIndex
                });
            }

        });
    }

};

export default SlidingItems;
