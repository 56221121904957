import Sticky from "../components/sticky";

export default (token) => {

    var toTop = token.element;
    var s = new Sticky(document.getElementsByTagName('main')[0], {

            wrap: false,
            mediaQueries: false,
            constrainTo: false,
            offsetTop: -300,
            deactivateOnSize: false,

            onStick: function () {
                TweenLite.fromTo(toTop, .5, {
                    yPercent: 100,
                    opacity: 0
                }, {
                    yPercent: 0,
                    opacity: 1
                })
            },

            onUnstick: function () {
                TweenLite.to(toTop, .5, {
                    yPercent: 100,
                    opacity: 0
                })
            }
        }
    );

    toTop.onclick = function () {
        TweenMax.to(window, .6, {
            scrollTo: {y: 0},
            ease: Power1.easeOut
        })
    };
};
