// eppoScroll /////////////////////////////

import ResizeListener from "./ResizeListener";
import {Draggable} from "gsap/Draggable";
import {toArray} from "../utils";

const EppoScroll = function (target, config = {}) {

    var host;

    //defaults
    this.vars = {
        acceptedScrollBarWidth: 5,
        ...config
    };

    this.scrollBarWidth = this.getScrollbarWidth();
    this.outer = document.createElement('div').AddClass('scroll-outer');
    this.host = document.createElement('div').AddClass('scroll-host');
    this.inner = document.createElement('div').AddClass('scroll-inner');
    this.target = target.AddClass('scroll-target');

    // init //

    this.init();
    target.eppoScroll = this;
};


EppoScroll.prototype = {
    constructor: EppoScroll,

    init: function () {

        var outer = this.outer,
            host = this.host,
            target = this.target,
            inner = this.inner,
            t = this;

        outer.style.cssText = 'overflow:visible; position:relative';

        host.style.position = 'relative';
        host.style.overflow = 'hidden';

        inner.style.cssText = 'position: relative; top:0; overflow: hidden; overflow-y: scroll; width: calc(100% + ' + (this.scrollBarWidth) + 'px);';
        // inner.setAttribute('tabindex', '-1');

        //add to DOM
        var fragment = document.createDocumentFragment();
        fragment.appendChild(outer);
        outer.appendChild(host);
        host.appendChild(inner);
        target.parentElement.insertBefore(fragment, target);
        inner.appendChild(target);

        if (this.vars.onInit) {
            this.vars.onInit({target: target, host: host, inner: inner})
        }

        if (this.scrollBarWidth <= this.vars.acceptedScrollBarWidth) {
            return {disabled: true};
        }

        this.createScrollTrack();

        inner.addEventListener('scroll', this.onScroll.bind(this));

        if (Draggable) {
            var nullObj = document.createElement('div');
            var startY,
                yMovement;
            Draggable.create(nullObj, {
                type: 'y',
                trigger: this.scrollHandle,
                cursor: 'initial',
                onDragStart: function () {
                    t.starDragScrollY = t.inner.scrollTop;
                    t.starDragHandleY = t.handleY;
                },
                onDrag: function () {
                    t.onDragHandle(this.y);
                },
                onDragEnd: function () {
                    TweenLite.set(nullObj, {y: 0})
                }
            });
            this.scrollTrack.addEventListener('click', this.onClickTrack.bind(this))
        }

        ResizeListener.add({
            el: target,
            cb: this.onScroll.bind(this)
        });

        this.constructor.instances.push(this);

        setTimeout(this.onScroll.bind(this), 200)

    },

    createScrollTrack: function () {
        var track = this.scrollTrack = document.createElement('div').AddClass('scroll-track');
        var handle = this.scrollHandle = document.createElement('div').AddClass('scroll-handle');
        handle.display = 'block';
        var parent = this.host.parentElement;

        track.appendChild(handle);
        parent.appendChild(track);
    },

    updateHandleHeight: function () {
        this.handleHeight = (this.scrollTrack.clientHeight) * (this.host.clientHeight / this.target.clientHeight);
        this.scrollHandle.style.height = this.handleHeight + 'px';
    },

    onClickTrack: function (event) {
        if (event.target == this.scrollHandle) return;
        var progress = event.offsetY / this.trackHeight;
        this.inner.scrollTop = Math.round(this.overflowHeight * progress);
    },

    onScroll: function () {
        var targetHeight = this.target.clientHeight;
        var hostHeight = this.host.clientHeight;
        var overflowHeight = this.overflowHeight = targetHeight - hostHeight;

        if (overflowHeight < 2) {
            this.scrollTrack.style.display = 'none';
        } else {
            this.scrollTrack.style.display = null;

        }
        var trackHeight = this.trackHeight = this.scrollTrack.clientHeight;
        var scrollY = this.inner.scrollTop;

        var scrollProgress = this.scrollProgress = (100 / overflowHeight * scrollY) / 100;

        this.updateHandleHeight();

        var trackHeightLeft = this.trackHeightLeft = trackHeight - this.handleHeight;
        var handleY = this.handleY = Math.round(scrollProgress * trackHeightLeft);

        TweenLite.set(this.scrollHandle, {
            y: handleY,
            force3D: true
        });

    },

    onDragHandle: function (movement) {
        var progress = ((this.starDragHandleY + movement) / this.trackHeightLeft);
        progress = Math.min(Math.max(progress, 0), 1);
        this.inner.scrollTop = Math.round(this.overflowHeight * progress);
    },

    getScrollbarWidth: function () {
        var el = document.createElement('div');
        el.style.position = 'fixed';
        el.style.overflow = 'scroll';
        el.style.width = '100px';
        el.style.height = '100px';

        //just to be x-browser safe
        var child = document.createElement('div');
        child.style.width = '120px';
        child.style.height = '120px';
        el.appendChild(child);

        document.body.appendChild(el);
        var sw = el.getBoundingClientRect().width - el.clientWidth;
        el.remove();
        return sw;
    }

};

EppoScroll.instances = [];

EppoScroll.create = function (targets, vars) {

    if (targets instanceof Element) {
        targets = [targets];
    } else if (targets instanceof HTMLCollection) {
        targets = toArray(targets);
    } else if (typeof targets == 'string') {
        targets = toArray(document.querySelectorAll(targets));
    } else if (!targets instanceof Array) {
        throw 'arguments[0] "targets" is invalid ' + targets + ' - must be Element, Array, HTMLCollection or query string';
    }

    for (var i = 0; i < targets.length; i++) {
        var target = targets[i];

        if (target.eppoScroll) {
            continue;
        }

        vars = vars || target.dataset.epposcroll || {};

        if (typeof vars == 'string') {
            vars = JSON.parse(vars);
        }

        new EppoScroll(target, vars)
    }
};

export default EppoScroll;
