import {Q, C} from '../J';
import {toArray, preventPageBleed, getScrollbarWidth, isElementIn} from '../utils';
import request from 'superagent';
import FormAssist from "../components/FormAssist";
import DropdownGroup from "../components/dropdownGroup";
import TypeFilter from "../components/typeFilter";
import EppoScroll from "../components/eppoScroll";
import Events from "../components/Events";

const filter = function (token) {


    


    var root = token.element,
        vars = token.data,
        events = root.events = new Events,
        form = Q(vars.input),
        f = new FormAssist(form),
        fEvents = f.events,
        /*dgEl = form.querySelector('.dropdown-group'),
        dg = new DropdownGroup(dgEl, {
            onActivateItem: activateDgItem
        }),*/
        actives = [],
        instance = {},
        dom,
        selectedParamName = vars.selectedParamName || 'filter';
    
    const allDgEl = form.querySelectorAll('.dropdown-group');
    const arrSC = [];
    const arrTF = [];
    

    if( allDgEl.length ) {
        for(let n=0; n<allDgEl.length; n++) {
            const dgr = allDgEl[n];

            new DropdownGroup(dgr, {
                onActivateItem: activateDgItem
            });

            const SC = toArray(dgr.getElementsByClassName('eppo-scroll'));
            if( SC.length > 0 ) {
                for(let a=0; a<SC.length; a++) {
                    arrSC.push(SC[a]);
                }
            }
            const TF = dgr.getElementsByClassName('type-filter');
            if( TF.length > 0 ) {
                for(let b=0; b<TF.length; b++) {
                    arrTF.push(TF[b]);
                }
            }
        }
    }

    var latestQuery = location.search.replace('?', '');
    var latestGroupQuery = latestQuery;
    //var scrollCandidates = toArray(dgEl.getElementsByClassName('eppo-scroll'));
    var scrollCandidates = arrSC;
    ////- cache dom
    dom = {
        form,
        summaryItems: C('div', {'class': 'filter-summary-items'})
    };

    ////- insert summaryElement
    dom.summaryItems.InsertAfter(dom.form);

    ////- create typeFilters
    //TypeFilter.create(toArray(dgEl.getElementsByClassName('type-filter')));
    TypeFilter.create(arrTF);

    ////- create scroll
    EppoScroll.create(scrollCandidates);


    ////- connect form, module events and renderer
    connect();
    
    fChange(f, true).then(() => {
    });

   
    function connect() {

        fEvents.addListener('change', fChange);
        dom.summaryItems.addEventListener('click', clickHandler)
    }


    function clickHandler(e) {


        var fRemove = isElementIn(e.target, actives), connectedTo;

        if (fRemove) {

            connectedTo = document.getElementById(fRemove.filterConnection);

            switch (connectedTo.tagName) {

                case 'INPUT':
                    clearConnected(connectedTo);
                    break;

                case 'FIELDSET':
                    connectedTo.filterItems.forEach(function (item) {
                        item = document.getElementById(item);
                        clearConnected(item)
                    });
                    break;
            }

            // todo: refactor formAssist naming convention + add update method

            // var values = f.values;
            f.updateValues();
            f.events.fireEvent('change', f);
        }
    }

    function clearConnected(connectedTo) {
        switch (connectedTo.type) {

            case 'checkbox':
            case 'radio':
                connectedTo.TreeSelect && connectedTo.TreeSelect.unCheckBranch();
                connectedTo.checked = false;
                break;

            case 'text':
            case 'number':
                connectedTo.value = '';
                break;
        }
    }

    function renderGroup(g) {

        return C('div', {'class': 'summary-group'}).Append(
            g.map(function (v) {
                return renderValue(v);
            })
        );
    }

    function renderValue(v) {
        var isBool = (
            v.type == 'checkbox' ||
            v.type == 'radio'
        );

        if (v.name == 'published') {
          v.value = v.title || null;
        }

        if (v.value !== null) {
          var textRepresentation = (
              isBool && v.title || '"' + v.value + '"'
          );

          var ret = C('a', {class: 'summary-item'})
              // .Append(C('i', {class:'icon-cross'}))
              .Append(C('span').Html(textRepresentation))
              .Cache(actives)
              .Call(function () {
                  this.filterConnection = v.id;
              });

          // when sub values, wrap value in grouping tag and render group to wrapper
          v.values && v.values.length &&
          (ret = C('div', {'class': 'item-has-group'}).Append(
              ret,
              renderGroup(v.values)
          ));
        }

        return ret;
    }

    function renderSelection(e) {

        actives = [];
        var v = e.values,
            fragment = C('fragment'),
            groups = v.structure.groups,
            elements = v.structure.elements,
            i, len = groups.length;

        elements.forEach(function (v) {
            fragment.appendChild(
                C('div', {class: 'filter-summary-item-group'}).Append(
                    renderValue(v)
                )
            )
        });

        groups.forEach(function (g) {

            fragment.appendChild(
                C('div', {class: 'filter-summary-item-group'}).Append(
                    C('a', {class: 'summary-group-name'})
                        // .Append(C('i', {class: 'icon-cross'}))
                        .Append(C('span').Html(g.title))
                        .Cache(actives)
                        .Call(function () {

                            this.filterConnection = g.id;
                            document.getElementById(g.id).filterItems = g.values.map(function (v) {
                                return v.id
                            });
                        })
                ).Append(
                    renderGroup(g.values)
                )
            )
        });


        dom.summaryItems.Html('').Append(fragment);
    }

    async function fChange(e, suppressGet) {
        renderSelection(e);
        if (suppressGet) return;
        var query = f.toQueryString();
        events.fireEvent('change', {
            documentUrl: getDocumentUrl(query),
            filterPath: getFilterUrl(vars.action, query)
        })
    }

    async function activateDgItem(e) {

        var content = e.active.Q('.dropdown-content'),
            toggle = e.active.Q('.dropdown-toggle');

        preventPageBleed(content, {
            offset: getScrollbarWidth(),
            width: e.windowWidth
        });

        var toggledName = toggle.dataset.filterName;
        var noFetch = toggle.dataset.filterNoFetch;

        if (noFetch) {

            return;
        }

        var query = f.toQueryString();
        latestGroupQuery = query;

        var filterQuery = [query, 'type=json', `${selectedParamName}=${toggledName}`].filter(function (s) {
            return s.length > 1
        }).join('&');

        var filterUrl = `${vars.action}?${filterQuery}`;

        scrollIntoView(content, toggle);

        var layer = addProtectiveDgLayer(content);
        var res, formData;

        switch (vars.method) {

            case 'post':
                formData = queryStringToVendoFormData(filterQuery);
                formData.append('filter', toggledName);

                res = await request
                    .post(vars.action)
                    .set('Accept', 'application/json')
                    .send(formData);

                var groupList = res.body.find(function (res) {
                    return res.id == 'filterGroupList';
                });

                content.Q('ul').Html(groupList && groupList.html || '');

                break;

            default:
                res = await request
                    .get(filterUrl)
                    .set('Accept', 'application/json');

                content.Q('ul').Html(res.body.list);
        }

        TweenLite.to(layer, .15, {
            opacity: 0, onComplete: function () {
                layer.remove()
            }
        });

        scrollIntoView(content, toggle);

        f.updateItems(content, toggle);
    }

    function addProtectiveDgLayer(content) {
        return C('div', {
            'class': 'dgProtect',
            style: 'background: rgba(255,255,255,.5); position: absolute; top: 0; left: 0; bottom: 0; right: 0;'
        }).AppendTo(content);
    }

    function scrollIntoView(content, toggle) {
        var b = content.getBoundingClientRect().bottom + scrollY;
        var t = toggle.getBoundingClientRect().top + scrollY;

        var destination = t + innerHeight < b ?
            t :
            b - innerHeight + 20;

        if (b > scrollY + innerHeight) {

            TweenMax.to(window, .6, {
                scrollTo: destination,
                ease: Power2.easeInOut
            })
        }
    }

    return {
        events
    }

};

//// FNs

function getDocumentUrl(query) {
    return [location.origin, location.pathname, `?${query}`].filter(function (s) {
        return s !== '?'
    }).join('');
}

function getFilterUrl(action, query) {
    return [action, `?${query}`].filter(function (s) {
        return s !== '?'
    }).join('');
}

module.exports = filter;
export default filter;
