import React, {render} from 'react';
import SimpleMessage from "../preactComponents/SimpleMessage";

export default (token) => {

    const {data} = token;

    render(
        <SimpleMessage
            placeholder={data.placeholder}
            name={'msg'}
            action={data.action}
            body={data.body || {}}
            messages={token.data.messages || []}
        />, token.element
    );

    return token;
};
