const List = function (
    element,
    vars = {animate: 'FADE'}
) {
    this.element = element;
    this.vars = vars;

    var t = this;

};

List.prototype = {

    constructor: List,

    async promiseUpdateHTML(HTMLPromise) {

        const t = this;
        let html, animationComplete;

        try {
            [html, animationComplete] = await Promise.all(
                [
                    HTMLPromise,
                    t.animations[t.vars.animate].out(t.element)
                ]
            );

            if (typeof html != 'string') {
                throw `String expected but got <${html}>`;
            }

            this.element.Html(html)

        } catch (err) {
            console.error('Error:', err)
        }

        t.animations[t.vars.animate].in(t.element);

    },

    animations: {

        'FADE': {
            out(element) {
                return new Promise(resolve => {
                    TweenLite
                        .to(element, .2, {opacity: 0})
                        .onComplete(
                            resolve(true)
                        )
                })
            },
            in(element) {
                TweenLite.to(element, .2, {opacity: 1})
            }
        }
    }
};

export default List;
