import Events from "./Events";
import {toArray, isElementIn} from "../utils";

function scrollDropdownIntoView(active, targetClass) {

  var b = active.Q(`.${targetClass}-content`).getBoundingClientRect().bottom + scrollY;
  var t = active.Q(`.${targetClass}-toggle`).getBoundingClientRect().top + scrollY;

  var destination = t + innerHeight < b ?
                    t :
                    b - innerHeight + (vars.offset || 20);

  if (b > scrollY + innerHeight) {

    TweenMax.to(window, .6, {
      scrollTo: destination,
      ease: Power2.easeInOut,
      delay: .1
    })
  }
}

/**
 *
 * @param root
 * @param vars {{targetClass: string}}
 * @return {DropdownGroup}
 * @constructor
 */
var DropdownGroup = function (root, vars = {}) {

  this.vars = {
    targetClass: 'dropdown',
    ...vars,
  };

  this.root = root;

  addEventListener('click', this.clickHandler.bind(this));

  this.init();

};

DropdownGroup.prototype = {

  constructor: DropdownGroup,

  scrollIntoView: scrollDropdownIntoView,

  init: function () {

    var t = this;

    const {targetClass} = this.vars;

    this.events = new Events(this);
    this.dropdowns = [].slice.call(this.root.getElementsByClassName(`${targetClass}`));
    this.toggles = [].slice.call(this.root.getElementsByClassName(`${targetClass}-toggle`));
    this.closeBtns = [].slice.call(this.root.getElementsByClassName(`${targetClass}-close`));
    this.active = false;

    this.toggles.forEach(function (toggle) {

      var focusTrigger = ['BUTTON', 'A'].includes(toggle.tagName)
                         ? toggle
                         : toggle.querySelector('a, button');

      if (focusTrigger) {

        focusTrigger.addEventListener('focus', (e) => {
          var dropdown = isElementIn(toggle, t.dropdowns);
          if (!isElement(e.relatedTarget, dropdown, 10)) {
            t.activateItem(dropdown);
          }
        });

        //prevent focus from firing on click
        focusTrigger.addEventListener('mousedown', function (e) {
          e.preventDefault();
        })
      }
    }, this);

    this.constructor.instances.push(this);
  },

  clickHandler: function (event) {

    var relevantTarget;
    var target = event.target;

    if (relevantTarget = isElementIn(target, this.closeBtns)) {
      //target is closeBtn
      this.toggle(false);

    } else if (relevantTarget = isElementIn(target, this.toggles)) {
      //target is toggle
      this.toggle(relevantTarget);

    } else if (this.active) {
      //target is outside
      if (!this.active.contains(event.target)) {
        this.toggle(false);
      }
    }
  },

  keyUpHandler: function (e) {

    var code = e.code;

    if (code == 'Tab' && this.active) {

      if (!
        (isElement(e.target, this.active, 20))
      ) {
        this.deactivateItem(this.active);
        this.deactivate(this.active);
      }
    }

    if (code == 'Escape' || code == 'Enter') {

      e.preventDefault();
      this.toggle(false);
    }

  },

  keyDownHandler: function (e) {

    if (e.code == 'Tab') {

      this.tabFrom = e.target;

      var a = isElement(e.target, this.active) || isElement(e.target, document.body);

      if (a) {
        this.deactivateItem(this.active);
        this.deactivate(this.active);
      }


    }
  },

  toggle: function (target) {

    var preds = this.vars.closePredicates;

    if (preds) {
      var len = preds.length;
      while (len) {
        if (!preds[--len]()) return;
      }
    }

    if (target) {
      target = isElementIn(target, this.dropdowns);

      if (target == this.active) {
        //if target dropdown is active
        this.deactivateItem(target);
        this.deactivate();

      } else {
        //activate target
        this.activateItem(target);
      }
    } else {
      //no target supplied - click is outside
      if (this.active) {
        this.deactivateItem(this.active);
        this.deactivate();
      }
    }
  },

  activateItem: function (target) {

    var t = this;
    if (this.active) {
      this.deactivateItem(this.active);
    }

    const windowWidth = window.innerWidth;

    target.AddClass(`${this.vars.targetClass}_active`);

    this.boundKeyUpHandler = this.keyUpHandler.bind(this);
    this.boundKeyDownHandler = this.keyDownHandler.bind(this);

    addEventListener('keyup', this.boundKeyUpHandler);
    addEventListener('keydown', this.boundKeyDownHandler);

    this.events.fireEvent('activateItem', {
      active: target,
      windowWidth
    });

    this.vars.scrollIntoView && scrollDropdownIntoView(target, this.vars.targetClass);

    this.active = target;

    if (this.vars.onActivateItem) this.vars.onActivateItem.call(this, {
      active: this.active,
      windowWidth
    });

    var setFocus = target.Q('.set-focus');

    if (setFocus) {
      setFocus.focus();
      this.activeSetFocus = target.querySelector(`.${this.vars.targetClass}-toggle a`)
        || target.querySelector(`.${this.vars.targetClass}-toggle`);

      this.activeSetFocus.tabIndex = -1;
    }

    var blurs = target.QA('.dropdown-blur-evt');

    function blurFn(e) {

      var keepOpen = isElement(e.relatedTarget, target, 10);

      if (!keepOpen && e.relatedTarget != null) {

        t.deactivateItem(target);
        t.deactivate(target);
      }
    }

    blurs.forEach(function (inp) {
      inp.onblur = blurFn
    })
  },

  deactivateItem: function (target) {

    if (!target) {
      return
    }
    target.RemoveClass(`${this.vars.targetClass}_active`);

    removeEventListener('keyup', this.boundKeyUpHandler);
    removeEventListener('keydown', this.boundKeyDownHandler);

    this.events.fireEvent('deactivateItem', {active: this.active});
    if (this.vars.onDeactivateItem) this.vars.onDeactivateItem.call(this, {active: this.active});

    if (this.activeSetFocus) {
      this.activeSetFocus.tabIndex = 0;
      this.activeSetFocus = false;
    }
  },

  deactivate: function (lastTarget) {

    this.events.fireEvent('closed', {active: this.active});
    this.active = false;
    if (this.vars.onDeactivate) this.vars.onDeactivate({active: this.active});
  }

};

DropdownGroup.instances = [];

DropdownGroup.create = function (targets, vars) {
  var ret = [];

  if (targets instanceof Element) {
    targets = [targets];
  } else if (targets instanceof HTMLCollection) {
    targets = toArray(targets);
  } else if (typeof targets == 'string') {
    targets = toArray(document.querySelectorAll(targets));
  } else if (!targets instanceof Array) {
    throw 'arguments[0] "targets" is invalid ' + targets + ' - must be Element, Array, HTMLCollection or query string';
  }

  for (var i = 0; i < targets.length; i++) {
    var target = targets[i];

    target.eppo = target.eppo || {};
    if (target.eppo.dropdownGroup) {
      continue;
    }

    vars = vars || target.dataset.dropdowngroup || {};

    if (typeof vars == 'string') {
      vars = JSON.parse(vars);
    }

    ret.push(new DropdownGroup(target, vars));
  }
  return ret;
};

module.exports = DropdownGroup;
export default DropdownGroup;
