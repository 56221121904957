import React, {Component} from 'react';
import classNames from 'classnames';
import ContentEditable from "./ContentEditable";
import agent from 'superagent';

import IcoMail from './mail.svg';
import IcoClose from './close.svg';
import IcoSend from './send.svg';


export default class SimpleMessage extends Component {

    constructor(props = {}) {
        super();
    }

    static defaultProps = {
        name: null,
        blockName: 'SimpleMessage',
        placeholder: '',
        body: {},
        emailPlaceholder: 'Din e-postadresse',
        messages: []
    };

    state = {
        active: false,
        email: '',
        message: '',
        msgCount: 1,
        touched: false,
        pending: false,
        messages: []
    };

    componentDidMount() {
        this.setState({
            messages: this.props.messages.map((msg, i) => ({
                ...msg,
                id: 'initial' + i
            }))
        })
    }

    addMessage(msg) {

        const msgCount = this.state.msgCount + 1;
        const messages = [
            ...this.state.messages,
            {
                ...msg,
                id: msgCount
            }
        ];

        this.setState({
            msgCount,
            messages,
        }, () => {
            this.bodyEl && this.bodyEl.scrollTo({}, this.bodyEl.scrollHeight);
        })
    };

    handleInputChange = ({target, type}) => {

        const idle = target.name === 'message' && type === 'blur' || !type && target.value === this.props.placeholder;

        this.setState({
            [target.name]: target.value,
            idle
        });
    };

    handleKeyStroke = (e) => {
        e.code === 'Escape' && this.handleActivateTrigger();
    };

    handleActivateTrigger = (e) => {

        if (!this.state.active) {
            window.addEventListener('keydown', this.handleKeyStroke)
        } else {
            window.removeEventListener('keydown', this.handleKeyStroke);
        }

        this.setState({
            active: !this.state.active
        })
    };

    handleMessageError = (err) => {

        const body = err.response.body;

        Object.keys(body).forEach(field => {

            body[field].forEach(entry => {

                this.addMessage({
                    type: 'error',
                    message: entry
                })
            }, this)
        }, this);

        this.setState({
            pending: false
        })
    };

    handleMessageSuccess = (res) => {

        const body = res.body;

        this.addMessage({
            type: 'success',
            message: body.message,
        });

        this.setState({
            pending: false,
            message: ''
        });
    };

    handleSubmit = async (e) => {

        const {props, state} = this;

        this.setState({
            pending: true
        });

        let messageObject = {
            message: state.message,
            user: true
        };

        if (state.message && state.message !== props.placeholder) {
            this.addMessage(messageObject)
        } else {
            messageObject = {
                ...messageObject,
                message: ''
            }
        }

        const res = await agent.post(props.action)
            .send({
                ...props.body,
                message: messageObject.message,
                email: state.email,
            })
            .set('Accept', 'application/json')
            .set('X-Requested-With', 'XMLHttpRequest')
            .catch(this.handleMessageError);

        res && this.handleMessageSuccess(res);
    };

    render(props, state, context) {

        const block = props.blockName;

        return (
            <div className={classNames(block,
                {
                    [`${block}_active`]: state.active,
                    [`${block}_${props.name}`]: props.name,
                    [`${block}_idle`]: state.idle,
                    [`${block}_pending`]: state.pending
                },
            )}>
                {state.active &&
                <div className={`${block}-messageContainer`}>
                    <div className={`${block}-head`}>
                        <button className={`${block}-closeBtn`} onClick={this.handleActivateTrigger}><IcoClose
                            className={'ico ico_close'}/></button>
                    </div>
                    <div className={`${block}-body`} ref={el => this.bodyEl = el}>
                        {state.messages && state.messages.map((entry) =>
                            <div key={entry.id} className={classNames(`${block}-message`, {
                                [`${block}-message_user`]: entry.user,
                                [`${block}-message_system`]: !entry.user,
                                [`${block}-message_${entry.type}`]: !!entry.type
                            })}>
                                <div dangerouslySetInnerHTML={{__html: entry.message}}/>
                            </div>
                        )}
                        <ContentEditable
                            className={`${block}-messageInput`}
                            name={'message'}
                            placeholder={props.placeholder}
                            html={state.pending && '...sender' || state.message}
                            onChange={this.handleInputChange}
                        />
                    </div>
                    <div className={`${block}-foot`}>
                        <div className={`${block}-foot-email`}>
                            <input className={`${block}-emailInput`}
                                   name='email'
                                   placeholder={props.emailPlaceholder}
                                   value={state.email}
                                   onInput={this.handleInputChange}
                            />
                        </div>
                        <div className={`${block}-foot-send`}>
                            <button className={`${block}-sendBtn`} onClick={this.handleSubmit}><IcoSend
                                className={'ico ico_send'}/></button>
                        </div>
                    </div>
                </div>}

                {!state.active &&
                <div className={`${block}-triggerWrap`}>
                    <button className={`${block}-trigger`} onClick={this.handleActivateTrigger}>
                        <IcoMail className={'ico ico_bubble'}/>
                    </button>
                </div>}
            </div>
        )
    }
};
