import {C} from '../J';
import {Power1} from "gsap";

var zIndex = 1000;

var defaults = {
    inDuration: .4,
    outDuration: .4,
    inEase: Power1.easeInOut,
    outEase: Power1.easeInOut,
    opacity: .5
};

export default {

    zIndex: zIndex,

    add: function (vars) {

        vars = vars || {};
        vars = {
            ...defaults,
            ...vars,
        };

        var oEl = C('div', {'class': 'view-overlay'});
        vars.className && oEl.classList.add(vars.className);

        var zIndex = typeof vars.zIndex == 'number' ? vars.zIndex : ++this.zIndex;

        TweenLite.set(oEl, {
            background: '#000000',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: zIndex,
            opacity: 0
        });

        document.body.appendChild(oEl);

        var onComplete = vars.onComplete || noop;

        TweenLite.to(oEl, vars.inDuration, {
            opacity: vars.opacity,
            ease: vars.inEase,
            onComplete: onComplete
        });

        return this.remove(oEl, vars)
    },

    remove: function (el, vars) {
        return {
            element: el,
            remove: function () {
                TweenLite.to(el, vars.outDuration, {
                    opacity: 0,
                    ease: vars.outEase,
                    onComplete: function () {
                        el.remove()
                    }
                });
                return false;
            }
        }
    }
};



