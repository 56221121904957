// Sticky /////////////////////////////////////

import mq from "./mq";

var Sticky = function (element, vars) {

    var config = {
        offsetTop: 0,
        mediaQueries: ['medium', 'large'],
        constrainTo: false,
        deactivateOnSize: true,
        wrap: true,
        onStick: function () {
        },
        onUnstick: function () {
        },
        onStop: function () {
        },
        onStart: function () {
        },
        onActivate: function () {
        },
        onDeactivate: function () {
        },
        instance: this,
        tag: false
    };

    config = this.vars = Object.assign(config, vars || {});

    this.stickyEl = element;
    this.constrainTo = this.vars.constrainTo;
    this.init();

    this.constructor.instances.push(this);
};


Sticky.prototype = {

    constructor: Sticky,

    init: function () {

        var wrapper;
        this.logPredicate = this.vars.logPredicate;

        if (this.vars.wrap) {
            wrapper = this.wrapper = document.createElement('div').AddClass('sticky-wrapper');
            this.stickyEl.parentElement.insertBefore(wrapper, this.stickyEl);
            wrapper.appendChild(this.stickyEl);
        } else {
            wrapper = this.wrapper = this.stickyEl;
        }

        if (typeof this.vars.offsetTop == 'function') {
            this.offsetTop = {get: this.vars.offsetTop};
            Object.defineProperty(this, 'offsetTop', {get: this.vars.offsetTop})
        } else {
            this.offsetTop = this.vars.offsetTop;
        }

        this.handler = function () {

            var wrapRect = wrapper.getBoundingClientRect();
            var stickyRect = this.stickyEl.getBoundingClientRect();

            var constrainRect = this.constrainTo ? this.constrainTo.getBoundingClientRect() : {bottom: Infinity};

            var pastThreshold = wrapRect.top - this.offsetTop < 0;

            if (pastThreshold && !this.stuck) {
                this.stick();
            } else if (!pastThreshold && this.stuck === true) {
                this.unStick();
            } else if (constrainRect.bottom < stickyRect.bottom && this.stuck !== 'stopped' && this.constrainTo) {
                this.stop();
            } else if (stickyRect.top - this.offsetTop > 0 && this.stuck == 'stopped' && this.constrainTo) {
                this.start();
            }

        }.bind(this);

        this.wResizeHandler = function () {
            this.evaluateSize.call(this)
        }.bind(this);

        if (this.vars.deactivateOnSize) {
            addEventListener('resize', this.wResizeHandler);
        }

        if (this.vars.mediaQueries) {
            this.mediaHandler = this.evaluateMediaQueries.bind(this);
            mq.addListener('change', this.mediaHandler);
            this.mediaHandler({wState: mq.state});
        } else {
            window.addEventListener('scroll', this.handler);
            this.activate();
        }

    },

    activate: function () {
        this.active = true;
        window.addEventListener('scroll', this.handler);
        this.handler();
        this.vars.onActivate();
    },

    deactivate: function () {
        this.active = false;
        this.unStick();
        window.removeEventListener('scroll', this.handler);
        this.vars.onDeactivate();
    },

    evaluateMediaQueries: function (e) {
        if (!this.active && this.vars.mediaQueries.indexOf(e.wState) > -1) { //inactive & present in queries
            this.activate()
        } else if (this.active && this.vars.mediaQueries.indexOf(e.wState) == -1) { //active & not present in queries
            this.deactivate();
        }
        this.evaluateSize();
    },

    evaluateSize: function () {
        var height = this.stickyEl.clientHeight;
        var top = this.offsetTop;

        if (this.active && height + top > innerHeight) {
            this.deactivate();
        } else if (!this.active && height + top < innerHeight) {
            if (this.vars.mediaQueries.indexOf(mq.state) > -1) {
                this.activate();
            }
        }
    },

    log: function () {
        if (this.logPredicate) {
            console.log.apply(this, arguments);
        }
    },

    stick: function () {
        this.stuck = true;
        this.vars.onStick({
            wrapper: this.wrapper
        });
    },

    unStick: function () {
        this.stuck = false;
        this.vars.onUnstick();
    },

    stop: function () {
        this.stuck = 'stopped';
        this.vars.onStop();
    },

    start: function () {
        this.stuck = false;
    },

    destroy: function () {
        var instances = this.constructor.instances;

        this.deactivate();
        removeEventListener('resize', this.wResizeHandler);

        if (this.mediaHandler) {
            appEvents.removeListener('mediaquery', this.mediaHandler);
        }

        instances.splice(instances.indexOf(this), 1);

        delete this;
    }

};

Sticky.instances = [];

Sticky.doc = {

    version: 'beta',

    callSignature: [
        'element:element', {
            offsetTop: 'numeric value: number || function',
            mediaQueries: "['small', 'medium', 'large']:array",
            constrainTo: "element:element",
            deactivateOnSize: 'boolean "CLARIFY!"',
            onStick: 'callback:function',
            onUnstick: 'callback:function',
            onStop: 'callback:function',
            onStart: 'callback:function',
            onActivate: 'callback:function',
            onDeactivate: 'callback:function',
            wrap: 'boolean',
            tag: 'string'
        }
    ],

    props: {
        activate: 'fn() "activates instance"',
        deactivate: 'fn() "deactivates instance"',
        evaluateSize: 'fn() "evaluates height of sticky element, deactivates if sticks below window boundary and is active, deactivates if opposite is true"',
        destroy: 'fn() "destroys instance - TODO"',
        offsetTop: 'number "how many px to offset when sticky, static || dynamic"'
    },

    todo: [
        '?: add built in resize sensor/handler',
        '?: add handler for stick/unstick',
        '?: add/remove classNames for stuck/stopped',
        'add support for querySelectors',
        'add constructor method: create()'
    ]
};

export default Sticky;
module.exports = Sticky;
