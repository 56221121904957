var instance = null;
import VimeoPlayer from '@vimeo/player';
import modal from "./modal";
import {C, Q} from '../J';

function createInstance() {

    var videoPlayerElement = C('div', {'class': 'videoModal-player'});
    var videoPlayer = null;

    var t = {
        videos: [],
        modal: modal,
        addVideo
    };

    function clickHandler(e) {
        // var element = e.currentTarget;
        this.videoConfig && openVideoModal(this.videoConfig)
    }

    function getWidth() {
        return window.innerWidth > 1000 && window.innerWidth * .8 ||
            window.innerWidth * .95
    }

    function openVideoModal(config) {

        // console.log(getWidth());
        videoPlayer = new VimeoPlayer(videoPlayerElement, {
            id: config.id,
            playsinline: true,
            autoplay: true,
            width: getWidth()
        });

        t.modal
            .setContent(videoPlayerElement)
            .setClass('outer', 'videoModal')
            .open();

        // console.log(t);
    }

    function initElement(element) {
        element.AddClass('video');
        var btn = createPlayBtn();
        element.Prepend(btn);
        btn.addEventListener('click', clickHandler.bind(element));
    }

    function createPlayBtn() {

        var btn = C('a', {href: '#', 'class': 'videoPlayBtn'})
            .Append(
                C('div', {'class': 'videoPlayBtn-circle'})
                    .Append(
                        C('div', {'class': 'videoPlayBtn-arrow'})
                    )
            )
            .Append(
                C('div', {'class': 'videoPlayBtn-caption'}).Html('Spill av')
            );

        return btn;
    }

    function addVideo(element, vars) {

        element.videoConfig = vars;

        // console.log(vars);

        var videoConfig = {
            ...vars,
            element
        };

        initElement(element);

        this.videos.push(videoConfig);
    }

    return t;
}

export default (token) => {

    instance = instance || createInstance();
    instance.addVideo(token.element, token.data);

    // console.log(instance);

    return token;
};
