import SlidingItems from "../components/slidingItems";
import {C} from '../J';
import {throttle, isElementIn} from "../utils";

export default (token) => {

    var slidingSection = token.element;
    var slidingSectionHeader = slidingSection.Q('.h-lines-center');
    var recentItems = slidingSection.Q('.product-list-wrapper');

    var sliding = new SlidingItems(recentItems, {snap: 'left', changeAt: 'left'});

    var slidingNav = getSlidingItemsNav(sliding);
    var slidingNavWrapper = C('nav', {'class': 'grid grid-justify-center sliding-nav-wrapper'});

    slidingNavWrapper.Append(
        slidingNav.nav.AddClass('grid')
    ).AppendTo(slidingSection);

    slidingSectionHeader.Prepend(slidingNav.arrowNav.prev).Append(slidingNav.arrowNav.next);

    token.destroy = function () {

        slidingNav.destroy();
    };

    return token;
};


function getSlidingItemsNav(s, vars) {

    var defaults = {
        minStep: 2
    };

    vars = Object.assign(defaults, vars || {});

    var nav = C('div', {class: 'slit-nav'}),
        pages = [],
        i, pLen = 0,
        pageNavItems = [],
        arrowNavItems = [],
        activeNavItem,
        activePageIndex,
        oldPlen,
        arrowNav = {
            next: C('button', {title: 'Neste'}).AddClass('slit-next').Cache(arrowNavItems),
            prev: C('button', {title: 'Forrige'}).AddClass('slit-prev').Cache(arrowNavItems)
        },
        steps;

    window.addEventListener('click', clickHandler);

    function clickHandler(e) {

        var t,
            nextIndex,
            incBy;

        if (t = isElementIn(e.target, pageNavItems)) {

            s.goToPage(t.navIndex, pages);

        } else if (t = isElementIn(e.target, arrowNavItems)) {

            incBy = Math.max(
                Math.ceil(pages[pLen - 1].length / 2),
                vars.minStep
            );

            nextIndex = t.classList.contains('slit-next') ?
                Math.min(steps, s.activeIndex + incBy) :
                Math.max(0, s.activeIndex - incBy);

            s.changeActive(nextIndex);
        }
    }

    s.events.addListener('change', changeActiveNavItem);

    function changeActiveNavItem() {

        activePageIndex = s.getActivePage.call(s, pages);
        var newActiveItem = pageNavItems[activePageIndex];

        if (newActiveItem != activeNavItem) {
            activeNavItem.RemoveClass('active');
            activeNavItem = newActiveItem;
            activeNavItem.AddClass('active');
        }
    }

    function update() {

        oldPlen = pLen;
        pages = s.getPages();
        pLen = pages.length;

        var p, j, pItems, item;

        if (oldPlen == pLen) {
            return;
        }

        steps = 0;
        oldPlen = pLen;

        pageNavItems = [];

        var fragment = C('fragment'),
            navInner = C('div', {class: 'slit-nav-pages grid'});

        for (i = 0; i < pLen; i++) {
            var navItem = C('button', {});
            navItem.navIndex = i;
            fragment.appendChild(navItem.Cache(pageNavItems));
            p = pages[i];
            if (i != pLen - 1) {
                for (j = 0; j < p.length; j++) {
                    steps++
                }
            }
        }

        activeNavItem = nav;
        changeActiveNavItem();
        nav.innerHTML = '';
        nav.Append(navInner.Append(fragment));
    }

    var resizeHandler = throttle(update, 40);
    window.addEventListener('resize', resizeHandler);


    function destroy() {
        window.removeEventListener('click', clickHandler);
        window.removeEventListener('resize', resizeHandler);

        if (s) {
            s.events.removeListener('change', changeActiveNavItem);
        }
    }

    update();

    return {
        nav: nav,
        arrowNav: arrowNav,
        destroy: destroy,
        update: update
    }
}

