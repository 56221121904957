import {toArray} from "./utils";

const domState = {

  contentIsLoaded: false,
  isLoaded: false,

  _state: null,

  set state(v) {
    this._state = v;
    modules.initModules(v);
  },

  get state() {
    return this._state
  }
};

const obs = (muts) => {

  muts.forEach(mut => {

    let i;

    for (i = 0; i < mut.addedNodes.length; i++) {
      const node = mut.addedNodes[i];
      if(node.nodeType === 1) {

        if(!node.getElementsByClassName) continue;

        if(node.classList.contains('ui-idle')) {
          // console.log('init: ', node);
          register(node);
        }
        toArray(node.getElementsByClassName('ui-idle')).forEach(module => {
          // console.log('init: ', module);
          register(module);
        })
      }
    }

    for (i = 0; i < mut.removedNodes.length; i++) {
      var node = mut.removedNodes[i];
      if(node.nodeType === 1) {

        if(!node.getElementsByClassName) continue;

        if(node.classList.contains('ui-registered')) {
          // todo: kill module
          // console.log('kill: ', node);
          window.modules.destroyModules(node);
        }
        toArray(node.getElementsByClassName('ui-registered')).forEach(module => {
          // console.log('kill: ', module);
        })
      }
    }
  })
}

const moduleOberver = new MutationObserver(obs);

moduleOberver.observe(document.documentElement, {
  childList: true,
  subtree: true
});

const modules = window.modules = (function() {

  const waiting =  {
    asap: [],
    contentLoaded: [],
    loaded: []
  };

  const registered =  [];
  const init = {};  //add methods to init - path resolved with token.type

  function destroyModules(root) {

    if(root){
      registered
        .forEach(function (module) {

          root.contains(module.token.element) && destroyModule(module);
        })
    }
  }

  function destroyModule(module) {

    // console.log('destroyModule', module);

    if(!module.token.destroy) return;

    const i = registered.indexOf(module);
    i > -1 && registered.slice(i,1);

    module.token.destroy();
  }

  function initModules(s) {

    const arr = waiting[s];

    while(arr.length) {
      var token = arr.pop();
      token.element = typeof token.element == 'string' ? document.querySelector(token.element) : token.element;

      var elData = token.element.dataset;
      // todo: incompatible with array structure

      if(elData && elData[token.type]) {
        try {
          token.data = JSON.parse(elData[token.type])
        } catch (err) {
          console.error(`Module error. Failed to parse JSON at dataset.${token.type} in element: `, token.element);
          console.error(err);
          continue;
        }
      }

      if(init[token.type]) {

        token.element.AddClass('ui-registered');
        token.element.RemoveClass('ui-idle')

        try {
          registered.push( {
            token: token,
            module: init[token.type](token)
          });
        }catch (err) {
          console.error('Module failed to initialize, token received was: ', token);
          console.error(err);
        }
      }
    }
  }

  var api = {
    _waiting: waiting,
    _registered: registered,
    init: init,
    destroyModules: destroyModules,
    initModules: initModules
  };

  api.register = function(token) {

    token.init =
      token.init ||
      (!domState.state && 'contentLoaded') ||
      'asap';

    waiting[token.init].unshift(token);
    initModules('asap');
  };

  return api

})();

export function getModules(root) {

  root = root || document;
  const toRegister = toArray(root.getElementsByClassName('ui-idle'));

  toRegister.forEach(register);
}

export function updateModules(element) {
  getModules(element);
  modules.initModules(domState.state)
}

function validateDataset(s) {
  if(typeof s !== "string") throw ['Expected string for dataset.ui, got:', s]
}

function register(m) {
  const {ui} = m.dataset;

  try {
    validateDataset(ui)
  } catch (err) {
    console.warn(m);
    throw err
  }

  try {
    var token = JSON.parse(m.dataset.ui);
  }catch (err) {
    token = {
      type: ui
    }
  }

  token.element = m;
  modules.register(token);
}

// console.log('DOMContent');
window.addEventListener('DOMContentLoaded', function() {
  // console.log('DOMContentLoaded');
  getModules();
  domState.state ='contentLoaded'
});

window.addEventListener('load', function() {
  domState.state ='loaded'
});


export const lazyModule = (fn, extraTime = 0) => async (...args) => {

  const module = await new Promise((resolve => setTimeout(() => {
    fn().then(resolve)
  }, extraTime)));

  return module.default(...args);
}

export const state =  domState;
export const ui = modules.init;
export default modules;
