export const util_classNames = (...args) => {

    return args.map(arg => {

        const argType = typeof arg;

        return argType == 'string' && arg ||
            arg instanceof Array && classNames(...arg) ||
            argType == 'object' && Object.keys(arg)
                .map(key => arg[key] && key || null)
                .filter(entry => entry)
                .join(' ') ||
            null
    })
        .filter(entry => entry)
        .join(' ');
};
