import {toArray} from "./utils";

const J = (function () {

    // Query ////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    var query = function (q, r) {

        r = r || document;
        return r.querySelector(q);
    };

    var queryAll = function (q, r) {

        r = r || document;
        return toArray(r.querySelectorAll(q));
    };


    var queryClass = function (q, r) {

        r = r || document;
        return toArray(r.getElementsByClassName(q));
    };

    var queryTag = function (q, r) {

        r = r || document;
        return toArray(r.getElementsByTagName(q));
    };


    // Element //////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    const createElem = function (tagName, attrs, children = null) {

        if (tagName == 'fragment') {
            return document.createDocumentFragment()
        }

        var el = document.createElement(tagName);

        if (attrs) {

            for (var attr in attrs) {
                el.setAttribute(attr, attrs[attr]);
            }
        }

        children && el.Append(children);

        return el;
    };


    // Element Prototype ////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    const EP = Element.prototype;

    Object.defineProperty(EP, 'bar', {

        set: function (v) {
            this.innerHTML = v;
            console.log(this, 'EP');
        },

        get: function () {
            return this.innerHTML;
        }
    });

    Object.defineProperty(EP, 'InnerHTML', {

        set: function (v) {
            this.innerHTML = v;
        },

        get: function () {
            return this.innerHTML;
        }
    });

    EP.fooo = function () {
        return 'fooo1';
    };

    EP.Cache = function (cacheStorage, name) {

        const isArr = Array.isArray(cacheStorage);
        if (isArr) {
            cacheStorage.push(this);
        } else {
            cacheStorage[name] = this;
        }

        return this;
    };

    EP.Call = function (fn, thisArg) {

        fn.call(thisArg ? thisArg : this);
        return this;
    };

    EP.Append = function (el) {

        var args = arguments;

        if (typeof el == 'function') {
            el = el();
            args = el.length ? el : [el]
        }

        for (var i = 0; i < args.length; i++) {
            var arg = args[i];
            if (arg === null || arg === this.null) continue;

            arg = typeof arg == 'function' ? arg() : arg;

            if (arg instanceof Array) {
                for (var j = 0; j < arg.length; j++) {
                    this.appendChild(arg[j]);
                }
            } else {
                this.appendChild(arg);
            }
        }

        return this;
    };

    EP.SetStyle = function (prop, val) {

        if (!val) {
            this.style.removeProperty(prop);
            return this
        }

        this.style[prop] = val;
        return this;
    };

    EP.AppendTo = function (target) {

        target.appendChild(this);
        return this;
    };

    EP.Prepend = function (el) {

        var args = arguments;

        if (typeof el == 'function') {
            el = el();
            args = el.length ? el : [el]
        }

        for (var i = args.length - 1; i > -1; i--) {
            this.insertBefore(args[i], this.firstChild);
        }

        return this;
    };

    EP.PrependTo = function (target) {

        target.insertBefore(this, target.firstChild);
        return this;
    };

    EP.InsertBefore = function (target) {

        target.parentElement.insertBefore(this, target);
        return this;
    };

    EP.InsertAfter = function (target) {

        if (target.nextSibling) {
            target.parentElement.insertBefore(this, target.nextSibling);
        } else {
            target.parentElement.appendChild(this);
        }
        return this;
    };

    EP.Remove = function () {

        // events.fireEvent('innerhtml', {
        //     target: this,
        //     parent: this.parentElement
        // });

        this.remove();
        return this;
    };

    EP.Create = function (tagName, vars) {

        return createElem(tagName, vars);
    };

    EP.AddClass = function (c) {

        if (typeof c == 'string') {
            c = c.split(' ')
        }

        for (var i = 0; i < c.length; i++) {

            this.classList.add(c[i]);
        }
        return this
    };

    EP.RemoveClass = function (c) {

        if (typeof c == 'string') {
            c = c.split(' ')
        }

        for (var i = 0; i < c.length; i++) {

            this.classList.remove(c[i]);
        }
        return this
    };

    EP.ToggleClass = function (c) {

        this.classList.toggle(c);
        return this
    };

    EP.SetClass = function (v) {

        this.setAttribute('class', v);
        return this
    };

    EP.SetAttribute = function (attr, v) {

        this.setAttribute(attr, v);
        return this
    };

    EP.Html = function (v) {

        this.InnerHTML = v;
        return this
    };

    EP.Text = function (v) {

        this.textContent = v;
        return this
    };

    EP.Q = function (q) {

        return query.apply(null, [q, this])
    };

    EP.QA = function (q) {

        return queryAll.apply(null, [q, this])
    };

    EP.QC = function (q) {

        return queryClass.apply(null, [q, this])
    };

    EP.QT = function (q) {

        return queryTag.apply(null, [q, this])
    };


    // API //////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////


    var API = function () {
        //Hmmmmmm
    };

    API.Q = query;
    API.QA = queryAll;
    API.QT = queryTag;
    API.QC = queryClass;
    API.C = createElem;

    return API
})();

export const C = J.C;
export const Q = J.Q;
export const QA = J.QA;
export const QT = J.QT;
export const QC = J.QC;

export default J;
