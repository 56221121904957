import Events from './Events';
import {Q} from '../J';
import {toArray} from '../utils';

var FormAssist = function (rootElement, vars) {

    var t = this;
    var defaults = {};

    var f = rootElement;

    vars = vars || {};
    vars = this.vars = Object.assign(defaults, vars);

    if (!f) {
        f = document.currentScript.parentElement;
    }

    this.root = f instanceof Element ? f : document.querySelector(f);
    this.root.FormAssist = this;
    this.events = new Events;

    this.textInputBlurHandler = function (e) {
        this.evaluateTextInput.call(this, e.target);
    }.bind(this);

    this.root.addEventListener('change', this.changeHandler.bind(this));

    this.updateItems();
    t.updateView();
};

FormAssist.prototype = {

    constructor: FormAssist,

    evaluateTextInput: function (item) {
        item.setAttribute('data-hasvalue', item.value ? '1' : '0');
    },

    changeHandler: function (e) {

        this.events.fireEvent('change', {
            originalEvent: 'change',
            target: e.target,
            values: this.values
        });
    },

    updateItems: function () {

        var i, len, items, item, tag, type, value, bGroups, keyVals, name, id, ranges;

        items = this.items = [].slice.call(this.root.querySelectorAll('input[name], textarea[name], select[name]')).filter(function (item) {
            return (!item.disabled)
        });

        len = items.length;

        for (i = 0; i < len; i++) {

            item = items[i];
            if (item.FormAssisted) {
                continue;
            }

            tag = item.tagName;
            type = item.type;
            name = item.name;
            id = item.id || (item.id = item.name + '[' + i + ']');

            if (type == 'checkbox' || type == 'radio') {

            } else {
                item.onblur = this.textInputBlurHandler;
            }

            item.Form = item.Form || this.root;
            item.FormAssisted = true;
        }
    },

    toQueryString: function (exclude) {

        this.updateValues();
        exclude = exclude || [];

        var ret = [],
            keys, key,
            bools = this._values.booleans,
            keyVals = this._values.keyValues,
            ranges = this._values.ranges,
            entry,
            i, len;

        for (i = 0, keys = Object.keys(bools), len = keys.length; i < len; i++) {
            key = keys[i];

            if (exclude.indexOf(key) == -1) {

                entry = bools[key].filter(function (val) {
                    return val.checked
                }).map(function (val) {
                    return encodeURIComponent(val.value)
                });

                if (entry.length) {
                    ret.push(key + '=' + entry.join(','))
                }
            }
        }

        for (i = 0, len = keyVals.length; i < len; i++) {
            entry = keyVals[i];
            if (entry.value && exclude.indexOf(entry.name) == -1) {
                ret.push(entry.name + '=' + encodeURIComponent(entry.value))
            }
        }

        return ret.join('&');
    },

    get values() {
        this.updateValues();
        return this._values;
    },

    set values(v) {
        return this.setValues(v);
    },

    toRequestBody: function (exclude) {

        this.updateValues();
        exclude = exclude || [];

        var ret = [],
            keys, key,
            bools = this._values.booleans,
            keyVals = this._values.keyValues,
            ranges = this._values.ranges,
            entry,
            i, len;

        for (i = 0, keys = Object.keys(bools), len = keys.length; i < len; i++) {

            key = keys[i];
            if (exclude.indexOf(key) == -1) {

                entry = bools[key].filter(function (val) {
                    return val.checked
                }).map(function (val) {
                    return encodeURIComponent(val.value)
                });

                entry.forEach(function (val) {
                    ret.push({name: key, value: val})
                });
            }
        }

        for (i = 0, len = keyVals.length; i < len; i++) {

            entry = keyVals[i];
            if (entry.value && exclude.indexOf(entry.name) == -1) {
                ret.push({name: entry.name, value: encodeURIComponent(entry.value)});
            }
        }

        return ret;
    },

    clear: function () {

        var items = this.items, i, len = items.length, item, type, tag;
        for (i = 0; i < len; i++) {

            item = items[i];
            type = item.type;
            //tag = item.tagName;

            if (type == 'checkbox' || type == 'radio') {
                item.checked = false;
            } else {
                item.value = '';
            }
        }

        this.events.fireEvent('change', {
            originalEvent: 'clear',
            target: this.root,
            values: this.values
        });
    },


    getValuesInElement(root) {

        return toArray(root.querySelectorAll('input', 'textarea', 'select'))
            .filter(({type, value, name, checked}) => {

                return !(
                    !name || // no name
                    !value || // no value
                    ((type === 'checkbox' || type === 'radio') && !checked)  // unchecked booleans
                );
            }
        );
    },

    updateValues: function () {

        var i, len, items, item, tag, type, value, booleans, keyVals, name, id, ranges;

        var root = this.root;
        var elements = this.getValuesInElement(root);

        var groups = toArray(root.getElementsByClassName('f-group'));

        function getGroup(el) {
            return groups.filter(function (g) {
                return g.contains(el);
            })
        }

        function getStructure() {

            var formStructure = {
                groups: [],
                elements: []
            };

            while (elements.length) {

                var structure = [];
                var el = elements.shift();
                var group = getGroup(el);

                var inpObject = {
                    id: el.id,
                    title: el.title,
                    name: el.name,
                    value: el.value,
                    type: el.type,
                    values: []
                };

                if (group.length) {

                    structure = group.map(function (g) {
                        return g.dataset.title;
                    });

                    function insertLevel(insertion, to) {
                        // todo: remove fn?
                        insertion.values
                    }

                    var insert;
                    for (var i = 0, len = structure.length; i < len; i++) {

                        var entryTitle = structure[i],
                            newInsert;

                        if (i == 0 && len == 1) {

                            insert = formStructure.groups.filter(function (a) {
                                return a.title == entryTitle;
                            })[0];

                            (!insert) && (insert = {
                                title: entryTitle,
                                id: group[0].id,
                                values: []
                            }) && formStructure.groups.push(insert);

                            insert.values.push(inpObject);

                            continue;

                        } else if (i == 0) {

                            insert = formStructure.groups.filter(function (a) {
                                return a.title == entryTitle;
                            })[0];

                            continue;
                        }

                        //get insertion point for values
                        insert = (function (entryTitle) {
                            return insert.values.find(function (item) {
                                return item.title == entryTitle;
                            });
                        })(structure[i]);

                        // if last item, push values and mutate insert for next iteration in outer loop
                        if (i == len - 1) {
                            insert.values.push(inpObject);
                            insert = inpObject;
                        }
                    }
                } else {

                    //add values for unstructured element
                    formStructure.elements.push(inpObject);
                }
            }

            return formStructure;
        }

        this._values = {
            booleans: {},
            keyValues: [],
            structure: getStructure(),
            ranges: []
        };

        this.__values = [];


        ////////////////////// OLD ///////////////////////////////

        items = this.items;
        len = items.length;

        booleans = this._values.booleans;
        ranges = this._values.ranges;
        keyVals = this._values.keyValues = [];

        for (i = 0; i < len; i++) {
            item = items[i];
            tag = item.tagName;
            type = item.type;
            name = item.name;
            id = item.id || (item.id = item.name + '[' + i + ']');

            var entry = {};
            var labelText;
            entry.id = id;
            entry.value = item.value;

            if (type == 'checkbox' || type == 'radio') {
                if (item.checked) {
                    booleans[name] = booleans[name] || [];
                    entry.checked = item.checked;
                    entry.labelText =
                        item.label ||
                        (item.parentElement.tagName === 'LABEL' && item.parentElement) ||
                        this.root.querySelector(`[for='${entry.id}']`)?.firstChild?.textContent || '';

                    booleans[name].push(entry);
                }
            } else {
                entry.name = name;
                keyVals.push(entry);
            }
        }

        return this._values;
    },

    setValues: function (o) {

        var booleans = o.booleans, ranges = o.ranges, keyVals = o.keyValues, key, keys, el, name, id, value, entry, len,
            i, ii, status, changed = [];

        this.clear();

        if (booleans) {

            keys = Object.keys(booleans);
            len = keys.length;

            for (i = 0; i < len; i++) {

                key = keys[i];
                entry = booleans[key];

                for (ii = 0; ii < entry.length; ii++) {

                    el = document.getElementById(entry[ii].id);
                    status = el.checked;
                    el.checked = entry[ii].checked;
                    if (status != el.checked) {
                        changed.push(el)
                    }
                }
            }
        }

        if (keyVals) {

            len = keyVals.length;
            for (i = 0; i < len; i++) {

                status = el.value;
                entry = keyVals[i];
                el = document.getElementById(entry.id);
                el.value = entry.value;
                if (status != el.value) {
                    changed.push(el)
                }
            }
        }

        this.events.fireEvent('change', {
            originalEvent: 'set',
            target: this.root,
            values: this.values
        });

        this.updateView();
    },

    updateView: function () {

        var i, len, items, item, type;

        items = this.items;
        len = items.length;

        for (i = 0; i < len; i++) {
            item = items[i];
            type = item.type;

            if (!(type == 'checkbox' || type == 'radio')) {
                this.evaluateTextInput(item);
            }
        }
    }
};

module.exports = FormAssist;
export default FormAssist;
