import {toArray} from "../utils";

import Sticky from "../components/sticky";
import scrollWatcher from "../components/scrollWatcher";
import scrollToElement from "../utils/scrollToElement";

export default (token) => {

    var navRoot = token.element;

    var config = {
        onStick: function (o) {
            o.wrapper.style.height = navRoot.clientHeight + 'px';
        },
    };

    var vars = Object.assign(
        config,
        navRoot.dataset.itemScrollNav && JSON.parse(navRoot.dataset.itemScrollNav) || {}
    );

    var toWatch = toArray(document.querySelectorAll(vars.targets));

    var ul = document.createElement('ul').AddClass('scroll-nav-list');

    var scrollWatchers = scrollWatcher.getInstance();

    (function () {

        for (var i = 0; i < toWatch.length; i++) {

            var el = toWatch[i];
            var elConf = JSON.parse(el.dataset.scrollnavtarget);

            var li = document.createElement('li');
            var a = document.createElement('a');
            a.setAttribute('href', '#');
            a.innerHTML = elConf.title;

            ul.appendChild(li);
            li.appendChild(a);
            if (elConf.class) {
                li.classList.add(elConf.class);
            }

            (function (el) {
                a.addEventListener('click', function () {
                    scrollToElement(el, {
                        offset: 100
                    });
                });
            })(el);

            scrollWatchers.add(el, elConf.title, {
                type: 'active',
                offset: (outerHeight / 3.6),
                onEnter: (function (li) {
                    return function () {
                        li.AddClass('active');
                    }
                })(li),
                onLeave: (function (li) {
                    return function () {
                        li.RemoveClass('active');
                    }
                })(li)
            });
        }

        navRoot.appendChild(ul);

    })();

    var offset = vars.offset || 0;

    var s = new Sticky(navRoot, {
        offsetTop: offset,
        onStick: function (o) {
            vars.onStick(o);
            navRoot.style.top = offset + 'px';
            navRoot.AddClass('stuck');
        },
        onUnstick: function () {
            navRoot.style.removeProperty('top');
            navRoot.RemoveClass('stuck');
        },
        mediaQueries: ['medium', 'large', 'small']
    })

};
