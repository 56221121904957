import SlidingItems from "../components/slidingItems";

export default (token) => {

    var slidingCatNav = new SlidingItems(token.element, {
        snap: 'left',
        changeAt: 'center',
        initDuration: 0
    });

    return token;
};
